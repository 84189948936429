import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {ReservationImplicitService, EventService} from "@common/services";
import {EventSources, EventTypes} from "@common/utils";
import {ReservationAttributes, Addon} from "@common/typing";
import {datadogLogs} from "@datadog/browser-logs";
import {isEmpty} from "lodash";
import {ADDONS_BUTTON_LIST} from "../constants";
import {setAddonsButton, setAddonsToggle} from "./addonsSlice";

// Service singletons
const reservationService = ReservationImplicitService.getInstance();

export const fetchReservation = createAsyncThunk("reservation/fetchReservation", async (id: string, {dispatch, rejectWithValue}) => {
    try {
        const reservation = await reservationService.getReservation(id, false);
        if (isEmpty(reservation?.data)) {
            throw new Error("Reservation not found. Please make sure that the reservation ID exists.");
        }
        const response = await reservationService.getReservation(reservation?.data[0]?.id, true, "addon_menu");
        const addonsToggle = response?.included?.[0]?.attributes?.fees?.filter((addon: Addon) => addon.max_quantity === 1);
        const addonsButton = response?.included?.[0]?.attributes?.fees?.filter((addon: Addon) => addon.max_quantity > 0);
        dispatch(setAddonsToggle(addonsToggle));
        dispatch(setAddonsButton(addonsButton));
        return response;
    } catch (error) {
        const errors = error?.data?.errors?.[0];
        const errorToDisplay = errors?.detail?.message?.details[0] || errors?.detail?.message || error;
        EventService.dispatch(datadogLogs, {
            title: "Addons Fetch Reservation Fail",
            message: `Reservation Legacy ID: ${id} `,
            type: EventTypes.ADDONS_FETCH_RESERVATION_FAIL,
            source: EventSources.UI,
            level: EventService.ERROR_LEVEL,
            data: {error},
        });

        throw new Error(
            `Error fetching reservation. Please reload the page or try again later. If this message persists, please contact support. Details: ${errorToDisplay}`
        );
    }
});
export interface reservationState {
    reservationAttributes: ReservationAttributes;
    reservationId: string;
    modal: {
        error: boolean;
        errorMessage: string;
    };
    action: {
        fetching: boolean;
    };
    reservationAddonsToDisplay: Addon[];
}

export const initialState: reservationState = {
    reservationAttributes: null,
    reservationId: "",
    modal: {
        error: false,
        errorMessage: "",
    },
    action: {
        fetching: false,
    },
    reservationAddonsToDisplay: [],
};

const reservationSlice = createSlice({
    name: "reservation",
    initialState,
    reducers: {
        hideReservationErrorModal: (state) => {
            state.modal.error = false;
        },
        deleteReservationAddonsToDisplay: (state) => {
            state.reservationAddonsToDisplay = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReservation.pending, (state) => {
                state.action.fetching = true;
            })
            .addCase(fetchReservation.fulfilled, (state, {payload}) => {
                state.action.fetching = false;
                state.reservationAttributes = payload?.data?.attributes as any;
                state.reservationAddonsToDisplay = payload?.included[0]?.attributes?.fees?.filter((fee) =>
                    ADDONS_BUTTON_LIST.some((addon) => addon.tag === fee.fee_category_tag)
                ) as any;
                state.reservationId = payload?.data?.id;
            })
            .addCase(fetchReservation.rejected, (state, {error}: any) => {
                state.modal.errorMessage = error?.message;
                state.action.fetching = false;
                state.modal.error = true;
            });
    },
});

export const {hideReservationErrorModal, deleteReservationAddonsToDisplay} = reservationSlice.actions;

export default reservationSlice.reducer;
