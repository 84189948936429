import React, {useEffect, useState} from "react";
import {setAddonChecked, setAddonQuantity, setAddonToggleSelected, getAddonPreview, setToggleLoading} from "../../../store/addonsSlice";
import {ToggleButton} from "../../index";
import {useAppDispatch, RootState} from "../../../store/store";
import {useSelector} from "react-redux";
import {Addon, AddonPreviewData} from "@common/typing";
import {getUserInfo, getAddonMappedName, validateLateCheckoutAvailable, getLateCheckoutTooltipMessage} from "../../../utils";
import styles from "./AddonToggle.module.scss";
import {Tooltip} from "@vacasa/react-components-lib";
import {ADDONS_TOGGLE_LIST} from "../../../constants";
import {AddonToggleLoading} from "../../loaders/Loaders";

type Props = {
  addon: Addon;
};

export const AddonToggle = ({addon}: Props) => {
  //Redux state
  const dispatch = useAppDispatch();
  const reservationId = useSelector((state: RootState) => state.reservation.reservationId);
  const fetchingReservation = useSelector((state: RootState) => state.reservation.action.fetching);
  const fetchingUnit = useSelector((state: RootState) => state.unit.action.fetching);
  const fetchingAvailability = useSelector((state: RootState) => state.availability.action.fetching);
  const unitAttributes = useSelector((state: RootState) => state.unit.unitAttributes);
  const availabilityAttributes = useSelector((state: RootState) => state.availability.availabilityAttributes);
  const reservationAttributes = useSelector((state: RootState) => state.reservation.reservationAttributes);
  const isLoadingPreview = useSelector((state: RootState) => state.addons.addonToggle.loading);
  const unitAllowLateCheckout = useSelector((state: RootState) => state.unit.unitAttributes?.late_checkout_offer_exclude) === 0;
  const dateAvailable = useSelector((state: RootState) => state.availability.available);

  //Local state
  const [addonActive, setAddonActive] = useState<boolean>(false);
  const [lateCheckoutAvailable, setLateCheckoutAvailable] = useState<boolean>(false);
  const [lateCheckoutMessage, setLateCheckoutMessage] = useState("");
  const addonName = getAddonMappedName(addon?.fee_category_tag);
  const addonAvailable = lateCheckoutAvailable && unitAllowLateCheckout && dateAvailable;
  const {email: userEmail} = getUserInfo();

  //Events
  const handleToggleClick = async () => {
    dispatch(setToggleLoading(true));
    if (!addonActive) {
      setAddonActive(!addonActive);
      dispatch(setAddonChecked({checked: !addonActive}));
      dispatch(setAddonQuantity({quantity: 1}));

      const previewPayload: AddonPreviewData = {
        reservation_id: reservationId,
        addon_fees: {
          fee_id: addon?.fee_id,
          quantity: !addonActive ? 1 : 0,
          fee_category_tag: addon?.fee_category_tag,
          region_id: unitAttributes?.region_id,
          check_out_rate: availabilityAttributes?.rate,
        },
        adjusted_by_email: userEmail,
      };

      const previewResponse = await dispatch(getAddonPreview(previewPayload));
      dispatch(setAddonToggleSelected(addon));
      dispatch(setToggleLoading(false));

      if (previewResponse?.meta?.requestStatus === "rejected") {
        setAddonActive(false);
        return;
      }
    }
  };

  useEffect(() => {
    if (unitAttributes && reservationAttributes) {
      const unitTimezone = unitAttributes?.timezone;
      const lastNight = reservationAttributes?.last_night;
      setLateCheckoutAvailable(validateLateCheckoutAvailable(unitTimezone, lastNight));
      setLateCheckoutMessage(
        getLateCheckoutTooltipMessage({
          unitTimezone,
          lastNight,
          unitAllowLateCheckout,
          dateAvailable,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitAttributes]);

  return fetchingAvailability || fetchingReservation || fetchingUnit || isLoadingPreview ? (
    /* While the reservation is fetched we display a content loaders (skeleton) */
    <AddonToggleLoading isLoadingPreview={isLoadingPreview} />
  ) : (
    <>
      <div className={styles.switchAddonContainer}>
        <div className="flex">
          <span className={styles.addonName}>{addonName}</span>
          {addon.fee_category_tag === ADDONS_TOGGLE_LIST[0].tag && !addonAvailable && (
            <Tooltip message={lateCheckoutMessage}>
              <span className={styles.addonNoAvailable}> (Not available)</span>
            </Tooltip>
          )}
        </div>
        <ToggleButton
          label={addonActive ? "Active" : "Inactive"}
          value={addonActive}
          onChange={handleToggleClick}
          rounded={true}
          disabled={!addonAvailable}
        />
      </div>
      <hr className={styles.hrToggle} />
    </>
  );
};
