import React from "react";
import {RootState, useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {Addon, AddonPreviewData} from "@common/typing";
import {getAddonMappedName, getUserInfo} from "../../utils";
import {
  getAddonPreview,
  hideDeleteAddonRecommendedModal,
  setAddonDeletePreview,
  setAddonToggleSelected,
  setToggleLoading,
} from "../../store/addonsSlice";
import {Modal} from "antd";
import {Button, Icon} from "@vacasa/react-components-lib";
import {Spinner} from "../spinner/Spinner";
import styles from "./Modals.module.scss";
import cx from "classnames";

type Props = {
  addon?: Addon;
};

export const ModalDeleteRecommended = ({addon}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const isLoadingPreview = useSelector((state: RootState) => state.addons.action.loading);
  const isDeleteModalVisible = useSelector((state: RootState) => state.addons.modal.deleteRecommended);
  const reservationId = useSelector((state: RootState) => state.reservation.reservationId);
  const unitAttributes = useSelector((state: RootState) => state.unit.unitAttributes);
  const {email: userEmail} = getUserInfo();

  const handleClickDelete = async () => {
    const previewPayload: AddonPreviewData = {
      reservation_id: reservationId,
      addon_fees: {
        fee_id: addon?.fee_id,
        quantity: 0,
        fee_category_tag: addon?.fee_category_tag,
        region_id: unitAttributes?.region_id,
        check_out_rate: 1,
      },
      adjusted_by_email: userEmail,
    };

    await dispatch(getAddonPreview(previewPayload));
    dispatch(setAddonToggleSelected(addon));
    dispatch(setToggleLoading(false));
    dispatch(setAddonDeletePreview(true));
  };

  return (
    <Modal
      open={isDeleteModalVisible}
      closable={false}
      okButtonProps={{style: {display: "none"}}}
      cancelButtonProps={{style: {display: "none"}}}
      width={600}
      centered
    >
      {isLoadingPreview ? (
        <Spinner message="We are generating the finances preview... please wait!" size="large" />
      ) : (
        <>
          <div className="flex-column">
            <span className={styles.trashIcon}>
              <Icon.AlertTriangle height={45} width={45} />
            </span>
            <p className="text-center">
              Are you sure you want to waive this {getAddonMappedName(addon.fee_category_tag)}? This will impact the total cost of the reservation.
            </p>
          </div>
          <div className={cx("flex-centered-horizontally", styles.buttonsSection)}>
            <Button customClass="btn-cancel" onClick={() => dispatch(hideDeleteAddonRecommendedModal())}>
              Cancel
            </Button>
            <Button customClass="btn-delete" onClick={handleClickDelete}>
              Delete
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};
