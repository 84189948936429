import {Addon, AddonFinanceItem, Fee, FeesDifference, FinanceInformation, Rent, Tax} from "@common/typing";

//Returns the fees that are different from the addon selected
export const getOtherFees = (fees: Fee[], addonSelected: Addon): Fee[] => {
    return fees
        .filter((fee: Fee) => fee.name !== addonSelected?.fee_category_name)
        .map((fee) => {
            return {
                ...fee,
                name: fee.name.replace(" ", ""),
            };
        });
};

//Returns the taxes finances information
export const getTaxesFinanceInformation = (originalTaxes: Tax[], previewTaxes: Tax[]): FinanceInformation => {
    const totalOriginal = originalTaxes?.reduce((acc, tax) => acc + Number(tax.amount), 0);
    const totalPreview = previewTaxes?.reduce((acc, tax) => acc + Number(tax.amount), 0);

    return {
        totalOriginal,
        totalPreview,
        difference: Number((totalPreview - totalOriginal).toFixed(2)),
    };
};

export const getDifferenceBetweenTaxes = (originalTaxes: Tax[], previewTaxes: Tax[]): FeesDifference[] => {
    return originalTaxes.map((oT) => {
        const originalAmount = oT.amount;
        const previewAmount = previewTaxes.find((pT) => pT.id === oT.id).amount;

        return {
            amount: originalAmount,
            id: oT.id,
            name: oT.name,
            result: parseFloat(previewAmount) - parseFloat(originalAmount),
        };
    });
};

//Returns the rent finances information
export const getRentFinanceInformation = (originalRent: Rent[], previewRent: Rent[]): FinanceInformation => {
    const totalOriginal = originalRent?.reduce((acc, rent) => acc + Number(rent.rent), 0);
    const totalPreview = previewRent?.reduce((acc, rent) => acc + Number(rent.rent), 0);

    return {
        totalOriginal,
        totalPreview,
        difference: Number((totalPreview - totalOriginal).toFixed(2)),
    };
};

export const getOtherFeesGrouped = (otherFees: Fee[]): Fee[] => {
    return Object.entries(
        otherFees.reduce((acc, {id, name, amount}) => {
            acc[name] = acc[name]
                ? {
                      id: acc[name].id,
                      name,
                      amount: (Number(acc[name].amount) + Number(amount)).toFixed(2),
                  }
                : {id, name, amount};
            return acc;
        }, {} as Record<string, any>)
    ).map(([name, {id, amount}]) => ({name, id, amount}));
};

export const getDifferenceBetweenFees = (originalAndPreviewOtherFees: Fee[]): FeesDifference[] => {
    return originalAndPreviewOtherFees.reduce((acc, item) => {
        const existingItem = acc.find((prev) => prev.name === item.name);
        if (existingItem) {
            const newResult = (Number(existingItem.amount) - Number(item.amount)) * -1;
            const newItem: FeesDifference = {...item, result: newResult};
            return [...acc.filter((prev) => prev.name !== item.name), newItem];
        } else {
            const newItem: FeesDifference = {...item, result: 0};
            return [...acc, newItem];
        }
    }, []);
};

export const getTotalAmountSelectedFee = (fees: Fee[], addonSelected: Addon): number => {
    return fees.filter((fee: Fee) => fee.name === addonSelected?.fee_category_name).reduce((acc, fee) => acc + Number(fee.amount), 0);
};

export function getNegativeDifferences(
    original: AddonFinanceItem,
    preview: AddonFinanceItem,
    addonSelected?: Addon
): {
    rent: boolean;
    fees: boolean;
    taxes: boolean;
    total: boolean;
} {
    const otherFees = getOtherFees(original?.fees, addonSelected);
    return {
        rent:
            original?.rent?.reduce((total, currentValue) => total + Number(currentValue.rent), 0) >
            preview.rent.reduce((acc, currentValue) => acc + Number(currentValue.rent), 0),
        fees:
            original.fees?.reduce((total, currentValue) => total + Number(currentValue.amount), 0) >
            otherFees?.reduce((acc, currentValue) => acc + Number(currentValue.amount), 0),
        taxes:
            original.taxes?.reduce((total, currentValue) => total + Number(currentValue.amount), 0) >
            preview.taxes?.reduce((acc, currentValue) => acc + Number(currentValue.amount), 0),
        total: Number(original?.total) > Number(preview?.total),
    };
}
