import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import ContentLoader from "react-content-loader";
import styles from "./HeaderInformation.module.scss";
import {getFormattedDate, dayjs, getFormattedDateWithUTC} from "../../../utils";
import {LegacyUrlGenerator} from "@common/utils";
import {ModalError} from "../../modals";

type HeaderItemProps = {
  title: string;
  value: string | number | Date;
  isUrl?: boolean;
  isLoading?: boolean;
  url?: string;
};

const HeaderItem = ({title, value, isUrl, isLoading, url = "/"}: HeaderItemProps) => {
  return isLoading ? (
    <div>
      <ContentLoader viewBox="0 0 400 10" height={20} width={400}>
        <rect x="0" y="0" rx="3" ry="3" width="400" height="20" />
      </ContentLoader>
    </div>
  ) : (
    <div>
      <span className={styles.title}>{value && title}</span>
      <span className={styles.itemValue}>
        {isUrl ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {value?.toString()}
          </a>
        ) : (
          value?.toString()
        )}
      </span>
    </div>
  );
};

export const HeaderInformation = () => {
  //Redux state
  const reservationAttributes = useSelector((state: RootState) => state.reservation.reservationAttributes);
  const fetchingReservation = useSelector((state: RootState) => state.reservation.action.fetching);

  return (
    <>
      <div className="panel-container-flex-header public-font-regular">
        <div className={styles.leftPanel}>
          <HeaderItem
            title="Reservation"
            value={reservationAttributes?.legacy_reservation_id}
            isUrl={true}
            url={LegacyUrlGenerator.toReservation(reservationAttributes?.legacy_reservation_id)}
            isLoading={fetchingReservation}
          />
          <HeaderItem
            title="Unit"
            value={reservationAttributes?.legacy_unit_id}
            isUrl={true}
            url={LegacyUrlGenerator.toUnit(reservationAttributes?.legacy_unit_id)}
            isLoading={fetchingReservation}
          />
          <HeaderItem
            title="Created"
            value={getFormattedDateWithUTC(reservationAttributes?.created_at, "dddd, MMM DD, YYYY")}
            isLoading={fetchingReservation}
          />
        </div>
        <div className={styles.rightPanel}>
          <HeaderItem
            title="First Night"
            value={getFormattedDate(reservationAttributes?.first_night, "dddd, MMM DD, YYYY")}
            isLoading={fetchingReservation}
          />
          <HeaderItem
            title="Last Night"
            value={getFormattedDate(reservationAttributes?.last_night, "dddd, MMM DD, YYYY")}
            isLoading={fetchingReservation}
          />
          <HeaderItem
            title="Check Out"
            value={getFormattedDate(dayjs(reservationAttributes?.last_night).add(1, "day").toISOString(), "dddd, MMM DD, YYYY")}
            isLoading={fetchingReservation}
          />
        </div>
      </div>

      {/* Modal used to display an error */}
      <ModalError />
    </>
  );
};
