import React from "react";
import {AlertMessage} from "@vacasa/react-components-lib";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {TIME_TO_REDIRECT} from "../../constants";

export const Alerts = (): JSX.Element => {
  const showSuccessAddonAlert = useSelector((state: RootState) => state.addons.alert.success);
  const showFailAddonAlert = useSelector((state: RootState) => state.addons.alert.fail);
  return (
    <>
      {showSuccessAddonAlert && (
        <AlertMessage customClass="addon-alert" type="success">
          Your changes have been saved successfully! You will be redirected to Reservation Finances in Admin in {TIME_TO_REDIRECT / 1000} seconds.
        </AlertMessage>
      )}
      {showFailAddonAlert && (
        <AlertMessage customClass="addon-alert" type="error">
          An error ocurred while saving your changes. Please try again.
        </AlertMessage>
      )}
    </>
  );
};
