export const DOMAIN = window.location.hostname;
export const LOCAL = "development";
export const STAGING = "staging";
export const PRODUCTION = "production";

const ENV_STAGE = "vacasastage.";
const ENV_PROD = "vacasa.";

const {REACT_APP_OPTIMIZELY_SDK_KEY} = process.env;
const optimizelySdkKey = REACT_APP_OPTIMIZELY_SDK_KEY;

let env: string = null;

if (DOMAIN.includes(ENV_PROD)) {
    env = PRODUCTION;
} else if (DOMAIN.includes(ENV_STAGE)) {
    env = STAGING;
} else {
    env = LOCAL;
}

export const ENVIRONMENT = env;
export const OPTIMIZELY_SDK_KEY = optimizelySdkKey;
