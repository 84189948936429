import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {EventService, UnitImplicitService} from "@common/services";
import {EventSources, EventTypes} from "@common/utils";
import {UnitItemAttributes} from "@common/typing";
import {datadogLogs} from "@datadog/browser-logs";
import {isEmpty} from "lodash";

// Service singletons
const unitService = UnitImplicitService.getInstance();

export const fetchUnit = createAsyncThunk("unit/fetchUnit", async (id: number): Promise<UnitItemAttributes> => {
    try {
        const unit = await unitService.getUnitByLegacyId(id);
        if (isEmpty(unit)) {
            throw new Error("Unit not found. Please make sure that the Legacy Unit ID exists.");
        }
        return unit;
    } catch (error) {
        EventService.dispatch(datadogLogs, {
            title: "Addons Fetch Unit Fail",
            message: `Unit Legacy ID: ${id} `,
            type: EventTypes.ADDONS_FETCH_UNIT_FAIL,
            source: EventSources.UI,
            level: EventService.ERROR_LEVEL,
            data: {error},
        });

        throw new Error(error?.message);
    }
});
export interface unitState {
    unitAttributes: Partial<UnitItemAttributes>;
    modal: {
        error: boolean;
        errorMessage: string;
    };
    action: {
        fetching: boolean;
    };
}

const initialState: unitState = {
    unitAttributes: null,
    modal: {
        error: false,
        errorMessage: "Error fetching the unit. Please reload the page or try again later. If this message persists, please contact support.",
    },
    action: {
        fetching: false,
    },
};

const unitSlice = createSlice({
    name: "unit",
    initialState,
    reducers: {
        hideUnitErrorModal: (state) => {
            state.modal.error = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUnit.pending, (state) => {
                state.action.fetching = true;
            })
            .addCase(fetchUnit.fulfilled, (state, {payload}) => {
                state.action.fetching = false;
                state.unitAttributes = payload?.attributes;
            })
            .addCase(fetchUnit.rejected, (state, payload) => {
                state.action.fetching = false;
                state.modal.error = true;
                state.modal.errorMessage = payload?.error?.message;
            });
    },
});

export const {hideUnitErrorModal} = unitSlice.actions;

export default unitSlice.reducer;
