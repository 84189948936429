import React, {useState} from "react";
import {RootState, useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {getTripProtectionProviderName, getUserInfo} from "../../utils";
import {FEE_CATEGORY_TAGS, ADDON_TYPE, TRIP_PROTECTION_PROVIDERS_IDS, VALIDATE_GUEST_RESIDENCY} from "../../constants";
import {getAddonPreview, setTripProtectionSelected, updateAddAddonModal} from "../../store/addonsSlice";
import {Alert, Checkbox, Modal, Select} from "antd";
import {Button} from "@vacasa/react-components-lib";
import {Spinner} from "../spinner/Spinner";
import isNil from "lodash/isNil";
import styles from "./Modals.module.scss";
import cx from "classnames";

export const ModalTripProtection = (): JSX.Element => {
  //Redux state
  const dispatch = useAppDispatch();
  const reservationId = useSelector((state: RootState) => state.reservation.reservationId);
  const reservationAttributes = useSelector((state: RootState) => state.reservation.reservationAttributes);
  const isLoadingPreview = useSelector((state: RootState) => state.addons.action.loading);
  const isTripProtectionModalVisible = useSelector((state: RootState) => state.addons.modal.add);
  const options = useSelector((state: RootState) => state.addons.addonButtonList)
    .filter((addon) => addon.fee_category_tag === FEE_CATEGORY_TAGS.TRIP_PROTECTION)
    .map((addon, index: number) => ({value: addon.fee_id, label: `${index + 1}. ${getTripProtectionProviderName(addon?.fee_id)}`}));

  //Local state
  const [selectedOption, setSelectedOption] = useState(null);
  const [policyChecked, setPolicyChecked] = useState<boolean>(true);
  const {email: userEmail} = getUserInfo();

  // Handlers
  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
    setPolicyChecked(
      option !== TRIP_PROTECTION_PROVIDERS_IDS.PLAY_TRAVEL_PROTECTION_STD && option !== TRIP_PROTECTION_PROVIDERS_IDS.PLAY_TRAVEL_PROTECTION_CFAR
    );
  };

  const handleClickApply = async () => {
    const payload = {
      reservation_id: reservationId,
      addon_fees: {
        fee_id: selectedOption,
        quantity: 1,
        fee_category_tag: FEE_CATEGORY_TAGS.TRIP_PROTECTION,
      },
      adjusted_by_email: userEmail,
      legacy_reservation_id: reservationAttributes?.legacy_reservation_id,
      addonType: ADDON_TYPE.BUTTON,
    };

    const tripProtectionProvider = Object.keys(TRIP_PROTECTION_PROVIDERS_IDS).find((key) => TRIP_PROTECTION_PROVIDERS_IDS[key] === selectedOption);
    dispatch(
      setTripProtectionSelected({
        provider: tripProtectionProvider,
        isTripProtectionWithoutTransfer: true,
      })
    );
    await dispatch(getAddonPreview(payload));
    dispatch(dispatch(updateAddAddonModal(false)));
  };

  return (
    <Modal
      open={isTripProtectionModalVisible}
      closable={false}
      okButtonProps={{style: {display: "none"}}}
      cancelButtonProps={{style: {display: "none"}}}
      width={812}
      centered
    >
      {isLoadingPreview ? (
        <Spinner message="We are generating the finances preview... please wait!" size="large" />
      ) : (
        <>
          <span className={styles.tripProtectionModalTitle}>Add On (Add Trip Protection Cost)</span>
          <div>
            <div className={styles.inputDiv}>
              <span className={styles.inputTitle}>Select Trip Protection</span>
              <Select
                options={options}
                defaultValue="Select an option"
                className={styles.selectTripProtection}
                onChange={handleOptionChange}
                disabled={false}
              />
            </div>
            {selectedOption === TRIP_PROTECTION_PROVIDERS_IDS.PLAY_TRAVEL_PROTECTION_STD ||
            selectedOption === TRIP_PROTECTION_PROVIDERS_IDS.PLAY_TRAVEL_PROTECTION_CFAR ? (
              <div className={styles.tripPolicy}>
                <Alert
                  className={styles.tripAntAlert}
                  message="Play Travel Protection Policy"
                  showIcon
                  description={
                    <Checkbox
                      className={styles.tripAntCheckbox}
                      checked={policyChecked}
                      onChange={(e) => {
                        setPolicyChecked(!policyChecked);
                      }}
                    >
                      {selectedOption === TRIP_PROTECTION_PROVIDERS_IDS.PLAY_TRAVEL_PROTECTION_STD
                        ? VALIDATE_GUEST_RESIDENCY.STANDARD
                        : VALIDATE_GUEST_RESIDENCY.CFAR}
                    </Checkbox>
                  }
                  type="warning"
                />
              </div>
            ) : null}
            <br />
            <span className={styles.inputSubtitle}>
              For more details about trip protection{" "}
              <a className={styles.inputLink} href="https://compass.vacasa.com/page/5444?SearchId=4467068" target="_blank" rel="noopener noreferrer">
                visit this link
              </a>
            </span>
          </div>
          <div className={cx("flex-centered-horizontally", styles.buttonsSection)}>
            <Button customClass="btn-cancel" onClick={() => dispatch(dispatch(updateAddAddonModal(false)))}>
              Cancel
            </Button>
            <Button
              disabled={isNil(selectedOption) || !policyChecked}
              customClass={isNil(selectedOption) || !policyChecked ? "btn-select-disabled" : "btn-select"}
              onClick={handleClickApply}
            >
              Apply
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};
