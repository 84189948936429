import React from "react";
import {Icon} from "@vacasa/react-components-lib";
import {RootState, useAppDispatch} from "../../../store/store";
import {useSelector} from "react-redux";
import {displayAddNewAddonModal, displayDeleteAddonModal, updateAddAddonModal} from "../../../store/addonsSlice";
import {
  getAddonMappedName,
  getDifferenceBetweenFees,
  getDifferenceBetweenTaxes,
  getOtherFees,
  getOtherFeesGrouped,
  getRentFinanceInformation,
  getTaxesFinanceInformation,
  getTotalAmountSelectedFee,
} from "../../../utils";
import styles from "./AddonButtonTable.module.scss";
import {ModalDelete} from "../../modals";
import {Addon} from "@common/typing";
import {EditTwoTone, DeleteTwoTone} from "@ant-design/icons";
import {Button} from "antd";
import {AddonGenericLoader} from "../../loaders/Loaders";
import {FEE_CATEGORY_TAGS, FEE_NAMES, TRIP_PROTECTION_PROVIDERS, TRIP_PROTECTION_PROVIDERS_IDS} from "../../../constants";

type Props = {
  addon: Addon;
};

export const AddonButtonTable = ({addon}: Props) => {
  const dispatch = useAppDispatch();
  const addonPreview = useSelector((state: RootState) => state.addons.addonButton.preview);
  const addonSavedSuccessfully = useSelector((state: RootState) => state.addons.addonButton.saved);
  const isSaving = useSelector((state: RootState) => state.addons.action.saving);
  const fetching = useSelector((state: RootState) => state.reservation.action.fetching || state.unit.action.fetching);
  const originalFees = addonPreview && addonPreview?.data?.attributes?.original_finances?.fees;
  const previewFees = addonPreview && addonPreview?.data?.attributes?.preview_finances?.fees;
  const originalTaxes = addonPreview && addonPreview?.data?.attributes?.original_finances?.taxes;
  const previewTaxes = addonPreview && addonPreview?.data?.attributes?.preview_finances?.taxes;
  const originalRent = addonPreview && addonPreview?.data?.attributes?.original_finances?.rent;
  const previewRent = addonPreview && addonPreview?.data?.attributes?.preview_finances?.rent;
  const isTripProtectionWithTransfer = useSelector((state: RootState) => state.addons.tripProtectionSelected.isTripProtectionWithTransfer);
  const tripProtectionId = previewFees?.filter((fee) => fee.name === FEE_NAMES.TRIP_PROTECTION)[0]?.id ?? null;
  const tripProtectionProvider = Object.keys(TRIP_PROTECTION_PROVIDERS_IDS).find((key) =>
    isTripProtectionWithTransfer ? TRIP_PROTECTION_PROVIDERS_IDS[key] === tripProtectionId : TRIP_PROTECTION_PROVIDERS_IDS[key] === addon.fee_id
  );
  const tripProtectionSelected =
    TRIP_PROTECTION_PROVIDERS[useSelector((state: RootState) => state.addons.tripProtectionSelected.provider)] ??
    TRIP_PROTECTION_PROVIDERS[tripProtectionProvider];

  //Original and Preview fee finances that are different to the addon selected
  const originalOtherFees = addonPreview && getOtherFees(originalFees, addon);
  const previewOtherFees = addonPreview && getOtherFees(previewFees, addon);

  //Original and preview fees grouped by name
  const originalOtherFeesGrouped = addonPreview && getOtherFeesGrouped(originalOtherFees);
  const previewOtherFeesGrouped = addonPreview && getOtherFeesGrouped(previewOtherFees);

  //Merge original and preview fees finances grouped
  const originalAndPreviewOtherFees = addonPreview && [...originalOtherFeesGrouped, ...previewOtherFeesGrouped];

  //Difference between original and preview fees finances grouped. The array of objects returned has a new "result" property
  const differenceBetweenFees = addonPreview && getDifferenceBetweenFees(originalAndPreviewOtherFees);

  //Difference between original and preview taxes. The array of objects returned has a new "result" property
  const differenceBetweenTaxes = addonPreview && getDifferenceBetweenTaxes(originalTaxes, previewTaxes);

  //Original and preview amount of the addon selected
  const totalOriginalSelectedFee = addonPreview && getTotalAmountSelectedFee(originalFees, addon);
  const totalPreviewSelectedFee = addonPreview && getTotalAmountSelectedFee(previewFees, addon);
  const diffFees = Number((totalPreviewSelectedFee - totalOriginalSelectedFee).toFixed(2));

  //Taxes and Rent calculation
  const taxesFinanceInformation = addonPreview && getTaxesFinanceInformation(originalTaxes, previewTaxes);
  const rentFinanceInformation = addonPreview && getRentFinanceInformation(originalRent, previewRent);

  //Total costs
  const totalOriginalCost = addonPreview && Number(addonPreview?.data?.attributes?.original_finances?.total);
  const totalPreviewCost = addonPreview && Number(addonPreview?.data?.attributes?.preview_finances?.total);
  const totalDifferenceFees = addonPreview && differenceBetweenFees.reduce((total, fee) => total + Number(fee.result), 0);
  const totalOtherFees = addonPreview && previewOtherFees.reduce((total, fee) => total + Number(fee.amount), 0);
  const totalDiff = Number((totalPreviewCost - totalOriginalCost).toFixed(2));
  const differenceFee = totalDifferenceFees + totalPreviewSelectedFee - totalOriginalSelectedFee;

  type Actions = {
    [key: string]: any;
  };

  const ACTIONS: Actions = {
    edit: displayAddNewAddonModal(addon?.fee_category_tag),
    delete: displayDeleteAddonModal(),
  };

  const handleClickAction = (action: string) => {
    if (addonSavedSuccessfully || isSaving) return;
    dispatch(ACTIONS[action]);
  };

  const handleClickEdit = () => {
    dispatch(updateAddAddonModal(true));
  };

  return fetching ? (
    <AddonGenericLoader />
  ) : (
    <>
      {!addonPreview && addon?.current_quantity > 0 ? (
        <div className={styles.container}>
          <span className={styles.addonName}>
            {getAddonMappedName(addon?.fee_category_tag)}{" "}
            <strong className={styles.addonTag}>
              {addon?.current_quantity > 0 && !addonPreview
                ? addon.fee_name === FEE_NAMES.TRIP_PROTECTION && addon.fee_id === TRIP_PROTECTION_PROVIDERS_IDS.RED_SKY
                  ? TRIP_PROTECTION_PROVIDERS.RED_SKY
                  : tripProtectionSelected
                : tripProtectionSelected}
            </strong>
          </span>
          <div style={{display: "flex"}}>
            {addon?.max_quantity > 1 ? (
              <div style={{marginRight: "10px"}}>
                <Button className="btn-already-added" onClick={handleClickEdit}>
                  Edit <EditTwoTone className={addonSavedSuccessfully || isSaving ? styles.iconDisabled : styles.icon} twoToneColor="#0178ab" />
                </Button>
              </div>
            ) : null}
            <Button className="btn-already-added" onClick={() => handleClickAction("delete")}>
              Remove <DeleteTwoTone className={addonSavedSuccessfully || isSaving ? styles.iconDisabled : styles.icon} twoToneColor="#0178ab" />
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.totalContainer}>
          <span className={styles.totalSubtitle}>
            {getAddonMappedName(addon?.fee_category_tag)}{" "}
            {addon.fee_category_tag === FEE_CATEGORY_TAGS.TRIP_PROTECTION ? (
              <span className={styles.addonNamePreview}>{tripProtectionSelected}</span>
            ) : null}
          </span>
          {addon?.max_quantity > 1 ? (
            <div className={styles.totalButtons}>
              <Icon.Edit
                className={addonSavedSuccessfully || isSaving ? styles.iconDisabled : "cursor-pointer"}
                onClick={handleClickEdit}
                height={20}
                width={20}
              />
            </div>
          ) : null}
        </div>
      )}

      {!addonPreview && addon?.current_quantity > 0 ? (
        <></>
      ) : (
        <>
          {/* Rent */}
          <div>
            {/* Section Title */}
            <div className={styles.gridContainerTitle}>
              <div className={styles.gridItem}>Rent</div>
              <div className={styles.gridItem}>Before</div>
              <div className={styles.gridItem}>After</div>
              <div className={styles.gridItem}>Diff</div>
            </div>
            {/* Section Content */}
            <div className={styles.gridContainer}>
              <div className={styles.gridItemBold}>TOTAL</div>
              <div className={styles.gridItemGray}>${rentFinanceInformation.totalPreview.toFixed(2)}</div>
              <div className={styles.gridItemGray}>${(rentFinanceInformation.totalPreview + rentFinanceInformation.difference).toFixed(2)}</div>
              <div className={styles.gridItemGray}>
                <div
                  className={
                    rentFinanceInformation.difference > 0
                      ? styles.positiveAmount
                      : rentFinanceInformation.difference < 0
                      ? styles.negativeAmount
                      : styles.zeroAmount
                  }
                >
                  {rentFinanceInformation.difference > 0 && "+"}${rentFinanceInformation.difference.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          {/* Fees */}
          <div>
            {/* Section Title */}
            <div className={styles.gridContainerTitle}>
              <div className={styles.gridItem}>Fees</div>
              <div className={styles.gridItem}>Before</div>
              <div className={styles.gridItem}>After</div>
              <div className={styles.gridItem}>Diff</div>
            </div>
            {/* Section Content */}
            {differenceBetweenFees.map((fee, index) => (
              <div key={index} className={styles.gridContainer}>
                <div className={styles.gridItemLeft}>{fee.name}</div>
                <div className={styles.gridItemGray}>${fee.amount}</div>
                <div className={styles.gridItemGray}>${Math.abs(parseFloat(fee.amount) + fee.result).toFixed(2)}</div>
                <div className={styles.gridItemGray}>
                  <div className={fee.result > 0 ? styles.positiveAmount : fee.result < 0 ? styles.negativeAmount : styles.zeroAmount}>
                    {fee.result >= 0 ? (fee.result !== 0 ? "+" : "") : "-"}${Math.abs(fee.result).toFixed(2)}
                  </div>
                </div>
              </div>
            ))}
            <div className={styles.gridContainer}>
              <div className={styles.gridItemLeft}>
                {getAddonMappedName(addon.fee_category_tag)} (
                {addon.fee_category_tag === FEE_CATEGORY_TAGS.TRIP_PROTECTION ? (
                  <span className={styles.tripProtectionFeeBold}>{tripProtectionSelected}</span>
                ) : null}
                )
              </div>
              <div className={styles.gridItemGray}>${totalOriginalSelectedFee.toFixed(2)}</div>
              <div className={styles.gridItemGray}>${totalPreviewSelectedFee.toFixed(2)}</div>
              <div className={styles.gridItemGray}>
                <div className={diffFees > 0 ? styles.positiveAmount : diffFees < 0 ? styles.negativeAmount : styles.zeroAmount}>
                  {diffFees > 0 && "+"}${diffFees.toFixed(2)}
                </div>
              </div>
            </div>
            {/* Section Content */}
            <div className={styles.gridContainer}>
              <div className={styles.gridItemBold}>TOTAL</div>
              <div className={styles.gridItemGrayBold}>${(totalOtherFees + totalOriginalSelectedFee).toFixed(2)}</div>
              <div className={styles.gridItemGrayBold}>${(totalOtherFees + totalDifferenceFees + totalPreviewSelectedFee).toFixed(2)}</div>
              <div className={styles.gridItemGrayBold}>
                <div className={differenceFee > 0 ? styles.positiveAmount : differenceFee < 0 ? styles.negativeAmount : styles.zeroAmount}>
                  {differenceFee > 0 && "+"}${differenceFee.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          {/* Taxes */}
          <div>
            {/* Section Title */}
            <div className={styles.gridContainerTitle}>
              <div className={styles.gridItem}>Taxes</div>
              <div className={styles.gridItem}>Before</div>
              <div className={styles.gridItem}>After</div>
              <div className={styles.gridItem}>Diff</div>
            </div>
            {/* Section Content */}
            {differenceBetweenTaxes.map((tax, index) => (
              <div key={index} className={styles.gridContainer}>
                <div className={styles.gridItemLeft}>{tax.name}</div>
                <div className={styles.gridItemGray}>${tax.amount}</div>
                <div className={styles.gridItemGray}>${Math.abs(parseFloat(tax.amount) + tax.result).toFixed(2)}</div>
                <div className={styles.gridItemGray}>
                  <div className={tax.result > 0 ? styles.positiveAmount : tax.result < 0 ? styles.negativeAmount : styles.zeroAmount}>
                    {tax.result >= 0 ? (tax.result !== 0 ? "+" : "") : "-"}${Math.abs(tax.result).toFixed(2)}
                  </div>
                </div>
              </div>
            ))}
            {/* Section Content */}
            <div className={styles.gridContainer}>
              <div className={styles.gridItemBold}>TOTAL</div>
              <div className={styles.gridItemGrayBold}>${taxesFinanceInformation?.totalOriginal?.toFixed(2)}</div>
              <div className={styles.gridItemGrayBold}>${taxesFinanceInformation?.totalPreview?.toFixed(2)}</div>
              <div className={styles.gridItemGrayBold}>
                <div
                  className={
                    taxesFinanceInformation.difference > 0
                      ? styles.positiveAmount
                      : taxesFinanceInformation.difference < 0
                      ? styles.negativeAmount
                      : styles.zeroAmount
                  }
                >
                  {taxesFinanceInformation.difference > 0 && "+"}${taxesFinanceInformation.difference.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          {/* Total */}
          <div className={styles.totalFinalContainer}>
            {/* Section Title */}
            <div className={styles.gridContainerTitle}>
              <div className={styles.gridItem}>Total</div>
              <div className={styles.gridItem}>Before</div>
              <div className={styles.gridItem}>After</div>
              <div className={styles.gridItem}>Diff</div>
            </div>
            {/* Section Content */}
            <div className={styles.gridContainer}>
              <div className={styles.gridItemBold}>TOTAL</div>
              <div className={styles.gridItemGray}>${totalOriginalCost.toFixed(2)}</div>
              <div className={styles.gridItemGray}>${totalPreviewCost.toFixed(2)}</div>
              <div className={styles.gridItemGray}>
                <div className={totalDiff > 0 ? styles.positiveAmount : totalDiff < 0 ? styles.negativeAmount : styles.zeroAmount}>
                  {totalDiff > 0 && "+"}${totalDiff.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Modal used to Delete the addon added in the preview */}
      <ModalDelete addon={addon} />
    </>
  );
};
