import {Addon} from "@common/typing";
import {
    ADDONS_BUTTON_LIST,
    FEE_CATEGORY_TAGS,
    ADDONS_TOGGLE_LIST,
    DAYS_BEFORE_RESERVATION_START,
    LATE_CHECKOUT_VALIDATION_MESSAGES,
    OFFER_START_HOUR,
    TRIP_PROTECTION_PROVIDERS,
    TRIP_PROTECTION_PROVIDERS_IDS,
} from "../../constants";
import {dayjs} from "../Dates/dates-management";
import {LateCheckoutTooltipMessageData} from "../../typing/General";

export const getAddonsToggleAllowed = (addonsToggle: Addon[]): Addon[] => {
    return addonsToggle?.filter((item) => {
        return ADDONS_TOGGLE_LIST.some((filterItem) => filterItem.tag === item.fee_category_tag && filterItem.allowed);
    });
};

export const getAddonsButtonAllowed = (addonsButton: Addon[]): Addon[] => {
    return addonsButton?.filter((item) => {
        return ADDONS_BUTTON_LIST.some((filterItem) => filterItem.tag === item.fee_category_tag && filterItem.allowed);
    });
};

export const getAddonMappedName = (feeCategoryTag: string): string => {
    let mappedName: string;

    switch (feeCategoryTag) {
        case FEE_CATEGORY_TAGS.EXTENDED:
            mappedName = "Late Checkout";
            break;
        case FEE_CATEGORY_TAGS.TRIP_PROTECTION:
            mappedName = "Trip Protection";
            break;
        default:
            mappedName = "";
            break;
    }
    return mappedName;
};

/**
 * Validates whether the current time is within an acceptable range for late checkout.
 * @param unitTimeZone - The timezone of the accommodation unit.
 * @param lastNight - The date of the last night of stay.
 * @returns Whether the late checkout attempt is within an acceptable time range.
 */
export const validateCheckoutTooFar = (unitTimeZone: string, lastNight: string): boolean => {
    const currentTimeInUnitTimeZone = dayjs().tz(unitTimeZone); // Current time in the unit's timezone
    const lastNightDate = dayjs(lastNight); // Date of the last night of stay
    const reservationStartDate = lastNightDate.subtract(DAYS_BEFORE_RESERVATION_START, "day").format("YYYY-MM-DD: hh:mm:ss.SSS"); // Reservation start date
    // Calculate the cutoff time for late checkout offers
    const cutoffTime = dayjs(reservationStartDate).tz(unitTimeZone).hour(OFFER_START_HOUR).minute(0).second(0).millisecond(0);

    // Check if the current time is equal to or after the cutoff time
    const isWithinTimeframe = currentTimeInUnitTimeZone.isSame(cutoffTime) || currentTimeInUnitTimeZone.isAfter(cutoffTime);
    return isWithinTimeframe;
};

export const validateBeforeCheckoutTime = (unitTimeZone: string, lastNight: string) => {
    const now = dayjs().tz(unitTimeZone);
    const lastNightFormatted = dayjs(lastNight).format("YYYY-MM-DD: hh:mm:ss.SSS");
    const extendedCutoff = dayjs(lastNightFormatted).tz(unitTimeZone).set("hour", 16).set("minute", 0).set("second", 0).set("millisecond", 0);

    if (extendedCutoff.isAfter(now)) {
        return false;
    }

    return true;
};

export const validateLateCheckoutAvailable = (unitTimeZone: string, lastNight: string) => {
    return !validateBeforeCheckoutTime(unitTimeZone, lastNight) && validateCheckoutTooFar(unitTimeZone, lastNight);
};

/**
 * Returns the tooltip message for late checkout
 * @returns string message
 */
export const getLateCheckoutTooltipMessage = (data: LateCheckoutTooltipMessageData): string => {
    let message: string;
    const {unitAllowLateCheckout, unitTimezone, lastNight, dateAvailable} = data;
    const conditions: any[] = [
        [!unitAllowLateCheckout, LATE_CHECKOUT_VALIDATION_MESSAGES.NOT_OFFERED], // Late Checkout is not offered (LateCheckoutOfferExclude = 1) for the current unit.
        [validateBeforeCheckoutTime(unitTimezone, lastNight), LATE_CHECKOUT_VALIDATION_MESSAGES.TOO_CLOSE], // Late Checkout can't be added because the reservation is too close from checkout
        [!validateCheckoutTooFar(unitTimezone, lastNight), LATE_CHECKOUT_VALIDATION_MESSAGES.TOO_FAR], // Late Checkout can't be added because the reservation is too far from checkout
        [!dateAvailable, LATE_CHECKOUT_VALIDATION_MESSAGES.BLOCKED], // Late Checkout can't be added because another reservation is blocking it
    ];

    for (const [condition, msg] of conditions) {
        if (condition) {
            message = msg;
            break;
        }
    }

    return message || "";
};

export const getTripProtectionProviderName = (providerId: string): string => {
    switch (providerId) {
        case TRIP_PROTECTION_PROVIDERS_IDS.RED_SKY:
            return TRIP_PROTECTION_PROVIDERS.RED_SKY;
        case TRIP_PROTECTION_PROVIDERS_IDS.PLAY_TRAVEL_PROTECTION_STD:
            return TRIP_PROTECTION_PROVIDERS.PLAY_TRAVEL_PROTECTION_STD;
        case TRIP_PROTECTION_PROVIDERS_IDS.PLAY_TRAVEL_PROTECTION_CFAR:
            return TRIP_PROTECTION_PROVIDERS.PLAY_TRAVEL_PROTECTION_CFAR;
        default:
            return TRIP_PROTECTION_PROVIDERS.RED_SKY;
    }
};
