import React, {useLayoutEffect} from "react";
import {Header} from "../header";
import {Addons} from "../addons";
import {Recommended} from "../recommended";
import {Alerts} from "../../components/alerts/Alerts";
import {useParams} from "react-router-dom";
import {fetchUnit} from "../../store/unitSlice";
import {RootState, useAppDispatch} from "../../store/store";
import {fetchReservation} from "../../store/reservationSlice";
import {useSelector} from "react-redux";
import {fetchAvailability} from "../../store/availabilitySlice";

const MainView = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const reservationAttributes = useSelector((state: RootState) => state.reservation.reservationAttributes);

  //Get the reservation ID from the url
  const {id: reservationId} = useParams<{id: string}>();

  //Fetch reservation data
  useLayoutEffect(() => {
    const fetchReservationData = async () => {
      await dispatch(fetchReservation(reservationId));
    };
    fetchReservationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Fetch unit data
  useLayoutEffect(() => {
    const fetchUnitData = async () => {
      if (reservationAttributes) await dispatch(fetchUnit(reservationAttributes?.legacy_unit_id));
    };
    fetchUnitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationAttributes]);

  //Fetch availability data
  useLayoutEffect(() => {
    const fetchAvailabilityData = async () => {
      if (reservationAttributes) {
        const data = {
          unit_id: reservationAttributes?.legacy_unit_id,
          date: reservationAttributes?.last_night,
        };
        await dispatch(fetchAvailability(data));
      }
    };
    fetchAvailabilityData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationAttributes]);

  return (
    <>
      <Header />
      <Alerts />
      <Addons />
      <Recommended />
    </>
  );
};

export default MainView;
