import React from "react";
import {BsArrowLeftShort} from "react-icons/bs";
import {LegacyUrlGenerator} from "@common/utils";
import {useParams} from "react-router-dom";
import {redirectToURL} from "../../../utils";

type BackButtonProps = {
  text: string;
};

export const BackButton = ({text}: BackButtonProps) => {
  const {id} = useParams<{id: string}>();

  return (
    <>
      <span className="cursor-pointer" onClick={() => redirectToURL(LegacyUrlGenerator.toReservationFinance(id))}>
        <BsArrowLeftShort style={{verticalAlign: "middle"}} size={"1.5rem"} />
        {text}
      </span>
    </>
  );
};
