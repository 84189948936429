import React from "react";
import {RootState, useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import {getAddonMappedName, getUserInfo} from "../../utils";
import {Modal} from "antd";
import {Spinner} from "../spinner/Spinner";
import {ADDON_TYPE} from "../../constants";
import {Addon, AddonPreviewData} from "@common/typing";
import {getAddonPreview, hideDeleteAddonModal} from "../../store/addonsSlice";
import styles from "./Modals.module.scss";
import {Button, Icon} from "@vacasa/react-components-lib";
import cx from "classnames";

type Props = {
  addon?: Addon;
};

export const ModalDelete = ({addon}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const reservationId = useSelector((state: RootState) => state.reservation.reservationId);
  const reservationAttributes = useSelector((state: RootState) => state.reservation.reservationAttributes);
  const availabilityAttributes = useSelector((state: RootState) => state.availability.availabilityAttributes);
  const unitAttributes = useSelector((state: RootState) => state.unit.unitAttributes);
  const isLoadingPreview = useSelector((state: RootState) => state.addons.action.loading);
  const isDeleteModalVisible = useSelector((state: RootState) => state.addons.modal.delete);
  const {email: userEmail} = getUserInfo();

  const handleClickDelete = async () => {
    const previewPayload: AddonPreviewData = {
      reservation_id: reservationId,
      addon_fees: {
        fee_id: addon?.fee_id,
        quantity: 0,
        fee_category_tag: addon?.fee_category_tag,
        region_id: unitAttributes?.region_id,
        check_out_rate: availabilityAttributes?.rate,
      },
      adjusted_by_email: userEmail,
      legacy_reservation_id: reservationAttributes?.legacy_reservation_id,
      addonType: ADDON_TYPE.BUTTON,
    };

    await dispatch(getAddonPreview(previewPayload));
  };

  return (
    <Modal
      open={isDeleteModalVisible}
      okButtonProps={{style: {display: "none"}}}
      cancelButtonProps={{style: {display: "none"}}}
      closable={false}
      width={700}
      centered
    >
      {isLoadingPreview ? (
        <Spinner message="We are generating the finances preview... please wait!" size="large" />
      ) : (
        <>
          <div className="flex-column">
            <span className={styles.trashIcon}>
              <Icon.AlertTriangle height={45} width={45} />
            </span>
            <p className="text-center">
              Are you sure you want to waive this {getAddonMappedName(addon?.fee_category_tag)}? This will impact the total cost of the reservation.
            </p>
          </div>
          <div className={cx("flex-centered-horizontally", styles.buttonsSection)}>
            <Button customClass="btn-cancel" onClick={() => dispatch(hideDeleteAddonModal())}>
              Cancel
            </Button>
            <Button customClass="btn-delete" onClick={handleClickDelete}>
              Delete
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};
