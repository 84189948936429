import React from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {Modal} from "antd";
import {Button, Icon} from "@vacasa/react-components-lib";
import cx from "classnames";
import styles from "./Modals.module.scss";
import isEmpty from "lodash/isEmpty";
import {redirectToURL} from "../../utils";
import {LCO_REPORTS_SLACK_CHANNEL, TP_REPORTS_SLACK_CHANNEL} from "../../constants";
import {hideAddonErrorModal} from "../../store/addonsSlice";
import {hideReservationErrorModal} from "../../store/reservationSlice";

export const ModalError = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const isAddonErrorModalVisible = useSelector((state: RootState) => state.addons.modal.error);
  const isReservationErrorModalVisible = useSelector((state: RootState) => state.reservation.modal.error);
  const addonErrorMessage = useSelector((state: RootState) => state.addons.modal.errorMessage);
  const reservationErrorMessage = useSelector((state: RootState) => state.reservation.modal.errorMessage);

  const handleClickClose = () => {
    dispatch(hideAddonErrorModal());
    dispatch(hideReservationErrorModal());
  };

  return (
    <Modal
      open={isAddonErrorModalVisible || isReservationErrorModalVisible}
      okButtonProps={{style: {display: "none"}}}
      cancelButtonProps={{style: {display: "none"}}}
      closable={false}
      width={800}
      centered
    >
      <div className="flex-column">
        <span className={styles.trashIconError}>
          <Icon.AlertTriangle height={45} width={45} />
        </span>
        <p className={cx("text-center", styles.fetchError)}>{!isEmpty(reservationErrorMessage) ? reservationErrorMessage : addonErrorMessage}</p>
        <p className={cx("text-center", styles.fetchError)}>
          If you need further assistance, please take a screenshot of this error and post it in one of the following <strong>Slack Channels</strong>,
          depending of which addon you are adding/removing:{" "}
          <ul className={styles.listOfChannels}>
            <li>
              <a onClick={() => redirectToURL(LCO_REPORTS_SLACK_CHANNEL)} target="_blank" rel="noopener noreferrer">
                #tmp-addon-adjust-late-checkout
              </a>
            </li>
            <li>
              <a onClick={() => redirectToURL(TP_REPORTS_SLACK_CHANNEL)} target="_blank" rel="noopener noreferrer">
                #tmp-addon-adjust-trip-protection
              </a>
            </li>
          </ul>
        </p>
      </div>
      <div className={cx("flex-centered-horizontally", styles.buttonsSection)}>
        <Button customClass="btn-cancel" onClick={handleClickClose}>
          Close
        </Button>
        <Button customClass="btn-add-right" onClick={() => dispatch(() => window.location.reload())}>
          Reload
        </Button>
      </div>
    </Modal>
  );
};
