import {Addon} from "@common/typing";
import React, {useState} from "react";
import {RootState, useAppDispatch} from "../../store/store";
import {useSelector} from "react-redux";
import isEmpty from "lodash/isEmpty";
import {getAddonPreview, hideTransferAddonModal, setTripProtectionSelected, validateReservation} from "../../store/addonsSlice";
import {getUserInfo} from "../../utils";
import {FEE_CATEGORY_TAGS, ADDON_TYPE} from "../../constants";
import {Alert, Modal} from "antd";
import {Spinner} from "../spinner/Spinner";
import cx from "classnames";
import styles from "./Modals.module.scss";
import {Button} from "@vacasa/react-components-lib";
import {Image} from "@vacasa/react-components-lib";

type Props = {
  addon?: Addon;
};

const TripProtectionInformationAlert = ({message}): JSX.Element => {
  return (
    <Alert
      className={styles.alertTripProtectionInfo}
      description={
        <span>
          {message}{" "}
          <a className={styles.inputLink} href="https://compass.vacasa.com/page/5444?SearchId=4467068" target="_blank" rel="noopener noreferrer">
            visit this link
          </a>
        </span>
      }
      type="info"
      showIcon
    />
  );
};

export const ModalTransferTripProtection = ({addon}: Props): JSX.Element => {
  //Redux state
  const dispatch = useAppDispatch();
  const isLoadingPreview = useSelector((state: RootState) => state.addons.action.loading);
  const isTransferModalVisible = useSelector((state: RootState) => state.addons.modal.transfer);
  const reservationId = useSelector((state: RootState) => state.reservation.reservationId);
  const tripProtectionSelectedInfo = useSelector((state: RootState) => state.addons.tripProtectionSelected);
  const [replacementReservationId, setReplacementReservationId] = useState<string>("");
  const [validReservation, setValidReservation] = useState<boolean>(true);
  const [isValidatingReservation, setIsValidatingReservation] = useState<boolean>(false);

  // Handlers
  const handleReplacementReservationIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reservationEntered = e.target.value;
    const numericValue = reservationEntered.replace(/[^0-9]/g, "");

    if (isEmpty(numericValue) && reservationEntered.length > 0) return;
    setReplacementReservationId(reservationEntered);
  };

  const handleClickApply = async () => {
    setIsValidatingReservation(true);
    const {payload: response} = await dispatch(validateReservation(parseInt(replacementReservationId)));

    if (!response) {
      setValidReservation(false);
      setIsValidatingReservation(false);
      return;
    }

    const payload = {
      reservation_id: reservationId,
      addon_fees: {
        quantity: 1,
        fee_category_tag: FEE_CATEGORY_TAGS.TRIP_PROTECTION,
        replacement_reservation_id: response?.id,
      },
      adjusted_by_email: getUserInfo().email,
      addonType: ADDON_TYPE.BUTTON,
    };

    dispatch(setTripProtectionSelected({...tripProtectionSelectedInfo, isTripProtectionWithTransfer: true}));
    await dispatch(getAddonPreview(payload));
    dispatch(hideTransferAddonModal());
    setIsValidatingReservation(false);
  };

  return (
    <Modal
      open={isTransferModalVisible}
      closable={false}
      okButtonProps={{style: {display: "none"}}}
      cancelButtonProps={{style: {display: "none"}}}
      width={700}
      centered
    >
      {isLoadingPreview ? (
        <Spinner message="We are generating the finances preview... please wait!" size="large" />
      ) : (
        <>
          <span className={styles.tripProtectionModalTitle}>Add On (Transfer Trip Protection to new reservation)</span>
          <div>
            <div className={styles.inputDiv}>
              <span className={styles.inputTitle}>Transfer Trip Protection from reservation:</span>
              <div className={styles.inputContainer}>
                <input
                  className={styles.inputText}
                  type="text"
                  value={replacementReservationId}
                  placeholder="Reservation ID"
                  onChange={handleReplacementReservationIdChange}
                />
              </div>
            </div>
            {!validReservation ? (
              <span className={styles.errorMessage}>This reservation doesn't exist. Please double check the Reservation ID</span>
            ) : null}
            <br />
            <span className={styles.inputSubtitle}>
              <TripProtectionInformationAlert message="For more details about transferring trip protection" />
            </span>
          </div>
          <div className={cx("flex-centered-horizontally", styles.buttonsSection)}>
            <Button customClass="btn-cancel" onClick={() => dispatch(hideTransferAddonModal())}>
              Cancel
            </Button>
            <Button
              disabled={isEmpty(replacementReservationId)}
              customClass={isEmpty(replacementReservationId) ? "btn-select-disabled" : "btn-select"}
              onClick={handleClickApply}
            >
              Apply
            </Button>
            {isValidatingReservation ? <Image.Spinner style={{marginLeft: "11px"}} width={30} /> : null}
          </div>
        </>
      )}
    </Modal>
  );
};
