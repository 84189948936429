import React from "react";
import styles from "./ToggleButton.module.scss";

type Props = {
  label?: string;
  value: boolean;
  rounded?: boolean;
  disabled?: boolean;
  onChange: () => void;
};

export const ToggleButton = ({label, value, onChange, rounded, disabled}: Props): JSX.Element => {
  return (
    <>
      <div className={styles.toggleContainer}>
        <label className={styles.toggle}>
          <input type="checkbox" checked={value} onChange={onChange} disabled={disabled} />
          <span className={`${styles.slider} ${rounded && styles.rounded} ${disabled && styles.disabled}`}></span>
        </label>
        <span className={styles.toggleLabel}>{label}</span>
      </div>
    </>
  );
};
