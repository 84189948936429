import React from "react";
import {HeaderInformation, HeaderTitle, BackButton} from "../../components";

const Header = () => {
  return (
    <>
      <BackButton text="Back" />
      <HeaderTitle title="Edit Reservation Finances" />
      <HeaderInformation />
    </>
  );
};

export default Header;
