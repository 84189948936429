import React, {useState} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../store/store";
import {ToggleButton} from "../../toggle-button/ToggleButton";
import {cleanAddonTogglePreview, displayDeleteAddonRecommendedModal, persistAddon} from "../../../store/addonsSlice";
import {ModalDeleteRecommended} from "../../modals";
import {Addon} from "@common/typing";
import {
  getAddonMappedName,
  getDifferenceBetweenFees,
  getDifferenceBetweenTaxes,
  getOtherFees,
  getOtherFeesGrouped,
  getRentFinanceInformation,
  getTaxesFinanceInformation,
  getTotalAmountSelectedFee,
} from "../../../utils";
import {Button, Image} from "@vacasa/react-components-lib";
import styles from "./AddonToggleTable.module.scss";
import {AddonGenericLoader} from "../../loaders/Loaders";
import isNil from "lodash/isNil";

type Props = {
  addon: Addon;
};

export const AddonToggleTable = ({addon}: Props) => {
  //Redux State
  const dispatch = useAppDispatch();
  const deletePreview = useSelector((state: RootState) => state.addons.addonToggle.deletePreview);
  const addonPreview = useSelector((state: RootState) => state.addons.addonToggle.preview);
  const addonSaved = useSelector((state: RootState) => state.addons.addonToggle.saved);
  const reservationId = useSelector((state: RootState) => state.reservation.reservationId);
  const reservationAttributes = useSelector((state: RootState) => state.reservation.reservationAttributes);
  const addonSelected = useSelector((state: RootState) => state.addons.addonToggle.selected);
  const isSaving = useSelector((state: RootState) => state.addons.action.saving);
  const isDeleting = useSelector((state: RootState) => state.addons.action.deletingRecommended);
  const fetching = useSelector((state: RootState) => state.reservation.action.fetching || state.unit.action.fetching);
  const originalFees = addonPreview && addonPreview?.data?.attributes?.original_finances?.fees;
  const previewFees = addonPreview && addonPreview?.data?.attributes?.preview_finances?.fees;
  const originalTaxes = addonPreview && addonPreview?.data?.attributes?.original_finances?.taxes;
  const previewTaxes = addonPreview && addonPreview?.data?.attributes?.preview_finances?.taxes;
  const originalRent = addonPreview && addonPreview?.data?.attributes?.original_finances?.rent;
  const previewRent = addonPreview && addonPreview?.data?.attributes?.preview_finances?.rent;

  //Original and Preview fee finances that are different to the addon selected
  const originalOtherFees = addonPreview && getOtherFees(originalFees, addon);
  const previewOtherFees = addonPreview && getOtherFees(previewFees, addon);

  //Original and preview fees grouped by name
  const originalOtherFeesGrouped = addonPreview && getOtherFeesGrouped(originalOtherFees);
  const previewOtherFeesGrouped = addonPreview && getOtherFeesGrouped(previewOtherFees);

  //Merge original and preview fees finances grouped
  const originalAndPreviewOtherFees = addonPreview && [...originalOtherFeesGrouped, ...previewOtherFeesGrouped];

  //Difference between original and preview fees finances grouped. The array of objects returned has a new "result" property
  const differenceBetweenFees = addonPreview && getDifferenceBetweenFees(originalAndPreviewOtherFees);

  //Difference between original and preview taxes. The array of objects returned has a new "result" property
  const differenceBetweenTaxes = addonPreview && getDifferenceBetweenTaxes(originalTaxes, previewTaxes);

  //Original and preview amount of the addon selected
  const totalOriginalSelectedFee = addonPreview && getTotalAmountSelectedFee(originalFees, addon);
  const totalPreviewSelectedFee = addonPreview && getTotalAmountSelectedFee(previewFees, addon);
  const diffFees = addonPreview && Number((totalPreviewSelectedFee - totalOriginalSelectedFee).toFixed(2));

  //Taxes and Rent calculation
  const taxesFinanceInformation = addonPreview && getTaxesFinanceInformation(originalTaxes, previewTaxes);
  const rentFinanceInformation = addonPreview && getRentFinanceInformation(originalRent, previewRent);

  //Total costs
  const totalOriginalCost = addonPreview && Number(addonPreview?.data?.attributes?.original_finances?.total);
  const totalPreviewCost = addonPreview && Number(addonPreview?.data?.attributes?.preview_finances?.total);
  const totalDifferenceFees = addonPreview && differenceBetweenFees.reduce((total, fee) => total + Number(fee.result), 0);
  const totalOtherFees = addonPreview && previewOtherFees.reduce((total, fee) => total + Number(fee.amount), 0);
  const totalDiff = addonPreview && Number((totalPreviewCost - totalOriginalCost).toFixed(2));

  //Total diff fees
  const differenceFee = totalDifferenceFees + totalPreviewSelectedFee - totalOriginalSelectedFee;

  //Local State
  const [addonActive, setAddonActive] = useState<boolean>(!!addonPreview || addon?.current_quantity > 0);

  //Events
  const handleToggleClick = () => {
    if (addonSelected?.current_quantity === 0) {
      setAddonActive(!addonActive);
      dispatch(cleanAddonTogglePreview());
      return;
    }
    dispatch(displayDeleteAddonRecommendedModal());
  };

  const handleCancelPreview = () => {
    if (addonSelected?.current_quantity === 0) {
      setAddonActive(false);
    }

    dispatch(cleanAddonTogglePreview());
  };

  const handleSave = async () => {
    await dispatch(
      persistAddon({
        reservation_id: reservationId,
        adjustment_preview_id: addonPreview?.data?.id,
        legacy_reservation_id: reservationAttributes?.legacy_reservation_id,
      })
    );

    window.scrollTo(0, 0);
  };

  const handleSaveDelete = async () => {
    await dispatch(
      persistAddon({
        reservation_id: reservationId,
        adjustment_preview_id: addonPreview?.data?.id,
      })
    );

    window.scrollTo(0, 0);
  };

  return fetching ? (
    <AddonGenericLoader />
  ) : (
    <div>
      {!addonPreview && addon?.current_quantity > 0 ? (
        <div className={styles.container} style={{alignItems: "center"}}>
          <span className={styles.addonName}>
            {getAddonMappedName(addon?.fee_category_tag)}{" "}
            <span className={styles.addonTag}>{addon?.current_quantity > 0 && !addonPreview ? "Applied" : "Preview"}</span>
          </span>
          <ToggleButton
            label={addonActive ? "Active" : "Inactive"}
            value={addonActive}
            onChange={handleToggleClick}
            rounded={true}
            disabled={isSaving || addonSaved || isDeleting || !isNil(addonPreview)}
          />
        </div>
      ) : (
        <div className={styles.totalContainer}>
          <span className={styles.totalSubtitle}>
            {getAddonMappedName(addon.fee_category_tag)} Cost {addon?.current_quantity > 0 && !addonPreview ? "Applied" : "Preview"}
          </span>
          <div className={styles.totalButtons}>
            <ToggleButton
              label={addonActive ? "Active" : "Inactive"}
              value={addonActive}
              onChange={handleToggleClick}
              rounded={true}
              disabled={isSaving || addonSaved || isDeleting || !isNil(addonPreview)}
            />
          </div>
        </div>
      )}
      {!addonPreview && addon?.current_quantity > 0 ? (
        <></>
      ) : (
        <>
          {/* Rent */}
          <div>
            {/* Section Title */}
            <div className={styles.gridContainerTitle}>
              <div className={styles.gridItem}>Rent</div>
              <div className={styles.gridItem}>Before</div>
              <div className={styles.gridItem}>After</div>
              <div className={styles.gridItem}>Diff</div>
            </div>
            {/* Section Content */}
            <div className={styles.gridContainer}>
              <div className={styles.gridItemBold}>TOTAL</div>
              <div className={styles.gridItemGray}>${rentFinanceInformation.totalPreview.toFixed(2)}</div>
              <div className={styles.gridItemGray}>${(rentFinanceInformation.totalPreview + rentFinanceInformation.difference).toFixed(2)}</div>
              <div className={styles.gridItemGray}>
                <div
                  className={
                    rentFinanceInformation.difference > 0
                      ? styles.positiveAmount
                      : rentFinanceInformation.difference < 0
                      ? styles.negativeAmount
                      : styles.zeroAmount
                  }
                >
                  {rentFinanceInformation.difference > 0 && "+"}${rentFinanceInformation.difference.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          {/* Fees */}
          <div>
            {/* Section Title */}
            <div className={styles.gridContainerTitle}>
              <div className={styles.gridItem}>Fees</div>
              <div className={styles.gridItem}>Before</div>
              <div className={styles.gridItem}>After</div>
              <div className={styles.gridItem}>Diff</div>
            </div>
            {/* Section Content */}
            {differenceBetweenFees.map((fee, index) => (
              <div key={index} className={styles.gridContainer}>
                <div className={styles.gridItemLeft}>{fee.name}</div>
                <div className={styles.gridItemGray}>${fee.amount}</div>
                <div className={styles.gridItemGray}>${Math.abs(parseFloat(fee.amount) + fee.result).toFixed(2)}</div>
                <div className={styles.gridItemGray}>
                  <div className={fee.result > 0 ? styles.positiveAmount : fee.result < 0 ? styles.negativeAmount : styles.zeroAmount}>
                    {fee.result >= 0 ? (fee.result !== 0 ? "+" : "") : "-"}${Math.abs(fee.result).toFixed(2)}
                  </div>
                </div>
              </div>
            ))}
            <div className={styles.gridContainer}>
              <div className={styles.gridItemLeft}>{addon.fee_name}</div>
              <div className={styles.gridItemGray}>${totalOriginalSelectedFee.toFixed(2)}</div>
              <div className={styles.gridItemGray}>${totalPreviewSelectedFee.toFixed(2)}</div>
              <div className={styles.gridItemGray}>
                <div className={diffFees > 0 ? styles.positiveAmount : diffFees < 0 ? styles.negativeAmount : styles.zeroAmount}>
                  {diffFees > 0 && "+"}${diffFees.toFixed(2)}
                </div>
              </div>
            </div>
            {/* Section Content */}
            <div className={styles.gridContainer}>
              <div className={styles.gridItemBold}>TOTAL</div>
              <div className={styles.gridItemGrayBold}>${(totalOtherFees + totalOriginalSelectedFee).toFixed(2)}</div>
              <div className={styles.gridItemGrayBold}>${(totalOtherFees + totalDifferenceFees + totalPreviewSelectedFee).toFixed(2)}</div>
              <div className={styles.gridItemGrayBold}>
                <div className={differenceFee > 0 ? styles.positiveAmount : differenceFee < 0 ? styles.negativeAmount : styles.zeroAmount}>
                  {differenceFee > 0 && "+"}${differenceFee.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          {/* Taxes */}
          <div>
            {/* Section Title */}
            <div className={styles.gridContainerTitle}>
              <div className={styles.gridItem}>Taxes</div>
              <div className={styles.gridItem}>Before</div>
              <div className={styles.gridItem}>After</div>
              <div className={styles.gridItem}>Diff</div>
            </div>
            {/* Section Content */}
            {differenceBetweenTaxes.map((tax, index) => (
              <div key={index} className={styles.gridContainer}>
                <div className={styles.gridItemLeft}>{tax.name}</div>
                <div className={styles.gridItemGray}>${tax.amount}</div>
                <div className={styles.gridItemGray}>${Math.abs(parseFloat(tax.amount) + tax.result).toFixed(2)}</div>
                <div className={styles.gridItemGray}>
                  <div className={tax.result > 0 ? styles.positiveAmount : tax.result < 0 ? styles.negativeAmount : styles.zeroAmount}>
                    {tax.result >= 0 ? (tax.result !== 0 ? "+" : "") : "-"}${Math.abs(tax.result).toFixed(2)}
                  </div>
                </div>
              </div>
            ))}
            {/* Section Content */}
            <div className={styles.gridContainer}>
              <div className={styles.gridItemBold}>TOTAL</div>
              <div className={styles.gridItemGrayBold}>${taxesFinanceInformation?.totalOriginal?.toFixed(2)}</div>
              <div className={styles.gridItemGrayBold}>${taxesFinanceInformation?.totalPreview?.toFixed(2)}</div>
              <div className={styles.gridItemGrayBold}>
                <div
                  className={
                    taxesFinanceInformation.difference > 0
                      ? styles.positiveAmount
                      : taxesFinanceInformation.difference < 0
                      ? styles.negativeAmount
                      : styles.zeroAmount
                  }
                >
                  {taxesFinanceInformation.difference > 0 && "+"}${taxesFinanceInformation.difference.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
          {/* Total */}
          <div className={styles.totalFinalContainer}>
            {/* Section Title */}
            <div className={styles.gridContainerTitle}>
              <div className={styles.gridItem}>Total</div>
              <div className={styles.gridItem}>Before</div>
              <div className={styles.gridItem}>After</div>
              <div className={styles.gridItem}>Diff</div>
            </div>
            {/* Section Content */}
            <div className={styles.gridContainer}>
              <div className={styles.gridItemBold}>TOTAL</div>
              <div className={styles.gridItemGray}>${totalOriginalCost.toFixed(2)}</div>
              <div className={styles.gridItemGray}>${totalPreviewCost.toFixed(2)}</div>
              <div className={styles.gridItemGray}>
                <div className={totalDiff > 0 ? styles.positiveAmount : totalDiff < 0 ? styles.negativeAmount : styles.zeroAmount}>
                  {totalDiff > 0 && "+"}${totalDiff.toFixed(2)}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className={styles.buttonsSection}>
        {addon.current_quantity < 1 && (
          <div className="flex">
            {!isSaving && addonPreview && (
              <Button onClick={() => handleCancelPreview()} customClass={"btn-cancel"}>
                Cancel
              </Button>
            )}
            <Button
              disabled={isSaving || addonSaved || isDeleting}
              customClass={!addonPreview ? "btn-saved" : isSaving ? "btn-saving" : "btn-submit"}
              onClick={handleSave}
              variant="secondary"
            >
              {isSaving ? "Saving" : `Save ${getAddonMappedName(addon.fee_category_tag)} Cost`}
            </Button>
            {isSaving || isDeleting ? <Image.Spinner width={30} /> : null}
          </div>
        )}

        {deletePreview && addonPreview && (
          <div className="flex">
            {!isSaving && addonPreview && (
              <Button onClick={() => handleCancelPreview()} customClass={"btn-cancel"}>
                Cancel
              </Button>
            )}
            <Button
              disabled={isSaving || addonSaved || isDeleting}
              customClass={!addonPreview ? "btn-saved" : isSaving ? "btn-saving" : "btn-submit"}
              onClick={handleSaveDelete}
              variant="secondary"
            >
              {isSaving ? "Saving" : `Delete ${getAddonMappedName(addon.fee_category_tag)} Cost`}
            </Button>
            {isSaving || isDeleting ? <Image.Spinner width={30} /> : null}
          </div>
        )}
      </div>

      {/* Modal used to Delete the addon added in the preview */}
      <ModalDeleteRecommended addon={addon} />
    </div>
  );
};
