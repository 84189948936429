// Date Management
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import {FrontendConfiguration} from "@common/configuration";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

/**
 * Returns a date with the format 'dddd, MMM DD, YYYY', for example: Saturday, May 06, 2023
 * If no format is specified, it will return the date in the format 'DD-MM-YYYY'
 * @param {string} date
 * @param {string} [format="DD-MM-YYYY"]
 */
export const getFormattedDate = (date: string, format: string = "DD-MM-YYYY"): string => {
    return dayjs(date).format(format);
};

/**
 * Returns a date with the format 'dddd, MMM DD, YYYY', for example: Saturday, May 06, 2023 using UTC
 * If no format is specified, it will return the date in the format 'DD-MM-YYYY'
 * @param {string} date
 * @param {string} [format="DD-MM-YYYY"]
 */
export const getFormattedDateWithUTC = (date: string, format: string = "DD-MM-YYYY"): string => {
    const timezone = FrontendConfiguration.getTimeZone();
    const createdReservationDateFormatted = dayjs.utc(date).tz(timezone);
    return createdReservationDateFormatted.format(format);
};

export {dayjs};
