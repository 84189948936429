import React from "react";
import {AddonToggle, AddonToggleTable} from "../../components";
import {getAddonsToggleAllowed} from "../../utils";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {Addon} from "@common/typing";

const Recommended = (): JSX.Element => {
  const addonsToggle = useSelector((state: RootState) => state.addons.addonToggleList);
  const addonPreview = useSelector((state: RootState) => state.addons.addonToggle.preview);
  const addonsToggleAllowed = getAddonsToggleAllowed(addonsToggle);

  const AddonsNotConfigured = () => {
    return <span className="addon-not-configured">Addons are not configured for this Unit</span>;
  };

  return (
    <>
      <div className="panel-container-recommended">
        <h3 className="preview-subtitle-fee">Recommended</h3>
        {addonsToggleAllowed?.length === 0 ? (
          <AddonsNotConfigured />
        ) : (
          addonsToggleAllowed?.map((addonToggle: Addon) =>
            addonPreview || addonToggle.current_quantity > 0 ? (
              <AddonToggleTable addon={addonToggle} key={addonToggle.fee_id} />
            ) : (
              <AddonToggle key={addonToggle.fee_id} addon={addonToggle} />
            )
          )
        )}
      </div>
    </>
  );
};

export default Recommended;
