import {configureStore} from "@reduxjs/toolkit";
import {useDispatch} from "react-redux";
import AddonsReducer from "./addonsSlice";
import ReservationReducer from "./reservationSlice";
import UnitReducer from "./unitSlice";
import AvailabilityReducer from "./availabilitySlice";

export const store = configureStore({
    reducer: {
        addons: AddonsReducer,
        reservation: ReservationReducer,
        unit: UnitReducer,
        availability: AvailabilityReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
