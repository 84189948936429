import React from "react";
import {displayTransferAddonModal, updateAddAddonModal} from "../../../store/addonsSlice";
import {Icon, Button} from "@vacasa/react-components-lib";
import {ModalTransferTripProtection, ModalDelete, ModalTripProtection} from "../../modals";
import {useAppDispatch, RootState} from "../../../store/store";
import {useSelector} from "react-redux";
import styles from "./AddonButton.module.scss";
import {Addon} from "@common/typing";
import {AddonGenericLoader} from "../../loaders/Loaders";
import {getAddonMappedName} from "../../../utils";
import {FEE_CATEGORY_TAGS} from "../../../constants";

type Props = {
  addon: Addon;
};

export const AddonButton = ({addon}: Props) => {
  //Redux state
  const dispatch = useAppDispatch();
  const fetchingReservation = useSelector((state: RootState) => state.reservation.action.fetching);
  const fetchingUnit = useSelector((state: RootState) => state.unit.action.fetching);

  //Events
  const handleAdd = async () => {
    dispatch(updateAddAddonModal(true));
  };
  const handleTransfer = async () => {
    dispatch(displayTransferAddonModal());
  };

  return fetchingReservation || fetchingUnit ? (
    /* While the reservation is fetched we display a content loaders (skeleton) */
    <AddonGenericLoader />
  ) : (
    <>
      <div className={styles.container}>
        <span className={styles.addonName}>{getAddonMappedName(addon?.fee_category_tag)}</span>
        <div>
          <Button disabled={fetchingReservation} customClass="btn-add" onClick={handleAdd}>
            Add <Icon.Plus className={styles.icon} />
          </Button>
          {addon.fee_category_tag === FEE_CATEGORY_TAGS.TRIP_PROTECTION && (
            <Button disabled={fetchingReservation} customClass="btn-add-right" onClick={handleTransfer}>
              Transfer ID <Icon.Repeat className={styles.icon} />
            </Button>
          )}
        </div>

        {/* Modal to display a deletion alert message before the preview is generated */}
        <ModalDelete addon={addon} />

        {/* Modal used to transfer the addon on between reservations */}
        <ModalTransferTripProtection addon={addon} />

        {/* Modal used to display the Trip Protection Modal */}
        <ModalTripProtection />
      </div>
    </>
  );
};
