import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AvailabilityImplicitService, EventService} from "@common/services";
import {EventSources, EventTypes} from "@common/utils";
import {AvailabilityAttributesType, GetListResponse} from "@common/typing";
import {datadogLogs} from "@datadog/browser-logs";
import {getFormattedDate, dayjs} from "../utils";

// Service singletons
const availabilityService = AvailabilityImplicitService.getInstance();

export const fetchAvailability = createAsyncThunk(
    "availability/fetchAvailability",
    async (data: Partial<AvailabilityAttributesType>, {getState, dispatch}): Promise<GetListResponse<AvailabilityAttributesType>> => {
        const state: any = getState();
        const {date, unit_id} = data;
        const checkoutDateFormatted = getFormattedDate(dayjs(date).add(1, "day").toISOString(), "YYYY-MM-DD");
        const availabilityParams = {
            "filter[unit_id]": unit_id.toString(),
            "filter[date]": checkoutDateFormatted,
            "filter[available]": true,
        };
        try {
            return await availabilityService.getAvailability(availabilityParams);
        } catch (error) {
            EventService.dispatch(datadogLogs, {
                title: "Addons Fetch Availability Fail",
                message: `Unit Legacy ID: ${state?.unit?.unitAttributes?.id} `,
                type: EventTypes.ADDONS_FETCH_AVAILABILITY_FAIL,
                source: EventSources.UI,
                level: EventService.ERROR_LEVEL,
                data: {error: error?.message},
            });

            throw new Error(
                `Error fetching the availability. Please reload the page or try again later. If this message persists, please contact support. Details: ${error?.message}`
            );
        }
    }
);
export interface unitState {
    availabilityAttributes: AvailabilityAttributesType;
    available: boolean;
    modal: {
        error: boolean;
        errorMessage: string;
    };
    action: {
        fetching: boolean;
    };
}

const initialState: unitState = {
    availabilityAttributes: null,
    available: false,
    modal: {
        error: false,
        errorMessage: "Error fetching the availability. Please reload the page or try again later. If this message persists, please contact support.",
    },
    action: {
        fetching: false,
    },
};

const availabilitySlice = createSlice({
    name: "availability",
    initialState,
    reducers: {
        hideAvailabilityErrorModal: (state) => {
            state.modal.error = false;
        },
        setAvailable: (state, {payload}) => {
            state.available = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAvailability.pending, (state) => {
                state.action.fetching = true;
            })
            .addCase(fetchAvailability.fulfilled, (state, {payload}) => {
                state.action.fetching = false;
                state.availabilityAttributes = payload?.data.length > 0 ? payload?.data[0]?.attributes : null;
                state.available = payload?.data.length > 0;
            })
            .addCase(fetchAvailability.rejected, (state, payload) => {
                state.action.fetching = false;
                state.modal.error = true;
                state.modal.errorMessage = payload?.error?.message;
            });
    },
});

export const {hideAvailabilityErrorModal, setAvailable} = availabilitySlice.actions;

export default availabilitySlice.reducer;
