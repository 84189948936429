import React from "react";
import "./index.scss";
import {MainView} from "./views";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";

const App = () => {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/undefined" />} />
          <Route path="/:id" element={<MainView />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
