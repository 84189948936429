import React from "react";
import {AddonButton, AddonButtonTable} from "../../components";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {Addon} from "@common/typing";
import {getAddonMappedName, getAddonsButtonAllowed} from "../../utils";
import {Button, Image} from "@vacasa/react-components-lib";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import {persistAddon, cleanAddonButtonPreview, cleanAddonTogglePreview} from "../../store/addonsSlice";
import {FEE_CATEGORY_TAGS, ADDON_TYPE, OPTIMIZELY_MATCH} from "../../constants";

const Addons = (): JSX.Element => {
  //Redux State
  const dispatch = useAppDispatch();
  const addonsButton = useSelector((state: RootState) => state.addons.addonButtonList);
  const addonPreview = useSelector((state: RootState) => state.addons.addonButton.preview);
  const addonSavedSuccessfully = useSelector((state: RootState) => state.addons.addonButton.saved);
  const reservationAddonsToDisplay = useSelector((state: RootState) => state.reservation.reservationAddonsToDisplay);
  const isSaving = useSelector((state: RootState) => state.addons.action.saving);
  const isDeleting = useSelector((state: RootState) => state.addons.action.deleting);
  const reservationId = useSelector((state: RootState) => state.reservation.reservationId);
  const reservationAttributes = useSelector((state: RootState) => state.reservation.reservationAttributes);
  let addonsButtonAllowed = getAddonsButtonAllowed(addonsButton);
  const hasAnActiveTripProtectionFee = addonsButtonAllowed?.some(
    (addon) => addon?.fee_category_tag === FEE_CATEGORY_TAGS.TRIP_PROTECTION && addon?.current_quantity === 1
  );

  const handleSave = async () => {
    await dispatch(
      persistAddon({
        reservation_id: reservationId,
        adjustment_preview_id: addonPreview?.data?.id,
        legacy_reservation_id: reservationAttributes?.legacy_reservation_id,
        addonType: ADDON_TYPE.BUTTON,
      })
    );

    window.scrollTo(0, 0);
  };

  const cancelPreview = () => {
    if (addonSavedSuccessfully || isSaving) return;
    dispatch(cleanAddonButtonPreview());
    dispatch(cleanAddonTogglePreview());
  };

  const AddonNotConfigured = () => {
    return <span className="addon-not-configured">Addons are not configured for this Unit</span>;
  };

  if (!isNil(addonsButtonAllowed)) {
    addonsButtonAllowed = addonsButtonAllowed.filter((addonAllowed, index, self) =>
      hasAnActiveTripProtectionFee // If there is an active trip protection fee, only show the trip protection fee added
        ? addonAllowed.fee_category_tag === FEE_CATEGORY_TAGS.TRIP_PROTECTION && addonAllowed.current_quantity === 1
        : index === self.findIndex((value) => value.fee_category_tag === addonAllowed.fee_category_tag)
    );
  }

  return (
    <>
      <div className="panel-container-addons">
        <h3 className="preview-subtitle-fee">{addonPreview ? "Add On Preview" : "Add On"}</h3>
        {addonsButtonAllowed?.length === 0 ? (
          <AddonNotConfigured />
        ) : (
          /*Filter the addons that are allowed to be displayed (add the new addons on the constants file) */
          addonsButtonAllowed?.map((addonButton: Addon) =>
            addonPreview || addonButton.current_quantity > 0 ? (
              <div className="preview-container" key={addonButton?.fee_id}>
                <AddonButtonTable addon={addonButton} />
                <div className="flex-end">
                  {!isSaving && addonPreview && (
                    <Button onClick={() => cancelPreview()} customClass={"btn-cancel"}>
                      Cancel
                    </Button>
                  )}

                  {!addonPreview && !isEmpty(reservationAddonsToDisplay) ? (
                    <></>
                  ) : (
                    <>
                      <Button
                        disabled={isSaving || addonSavedSuccessfully || (!addonPreview && !isEmpty(reservationAddonsToDisplay))}
                        customClass={!addonPreview ? "btn-saved" : isSaving ? "btn-saving" : "btn-submit"}
                        onClick={handleSave}
                        variant="secondary"
                      >
                        {isSaving && !isDeleting ? "Saving" : `Save ${getAddonMappedName(addonButton.fee_category_tag)} Cost`}
                      </Button>
                    </>
                  )}
                  {isSaving && !isDeleting && (
                    <div className="button-space">
                      <Image.Spinner width={30} />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <AddonButton key={addonButton.fee_id} addon={addonButton} />
            )
          )
        )}
      </div>
    </>
  );
};

export default Addons;
