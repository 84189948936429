import React from "react";
import styles from "./Spinner.module.scss";
import {Spin} from "antd";

type SpinnerProps = {
  message: string;
  size?: "small" | "default" | "large";
};

export const Spinner = ({message, size = "default"}: SpinnerProps): JSX.Element => {
  return (
    <div className={styles.spinner}>
      <Spin size={size} />
      <span className={styles.spinnerText}>{message}</span>
    </div>
  );
};
