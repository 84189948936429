import React, {useEffect} from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {ImplicitFlow, ThemeProvider, JwtValidator} from "@vacasa/react-components-lib";
import DefaultTheme from "@vacasa/react-components-lib/src/themes/default";
import {FrontendConfiguration} from "@common/configuration";
import {Provider} from "react-redux";
import {store} from "./store/store";
import {LogsEvent, datadogLogs} from "@datadog/browser-logs";
import App from "./App";
import {obfuscateTokenFromUrl} from "@common/utils";

import {createInstance, OptimizelyProvider, withOptimizely, setLogger} from "@optimizely/react-sdk";
import {OPTIMIZELY_SDK_KEY} from "./config/optimizely";
import {nanoid} from "nanoid";

Sentry.init({
  dsn: FrontendConfiguration.getSentryDsn(),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.01,
});

const params = new URLSearchParams(window.location.search);
let token: string = params.get("token");
const adminConfig = FrontendConfiguration.adminConfig();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const optimizely: any = createInstance({
  sdkKey: OPTIMIZELY_SDK_KEY,
  eventBatchSize: 10,
  eventFlushInterval: 1000,
});

//If loaded, we remove the reference to force refreshed cache
sessionStorage.removeItem("cache-force-refreshed");

const environment = FrontendConfiguration.environment();
const isLocal = environment === "local";

//Log to datadog for dev, stage and prod
!isLocal &&
  datadogLogs.init({
    clientToken: FrontendConfiguration.getDataDogToken(),
    site: FrontendConfiguration.dataDogUrl(),
    forwardErrorsToLogs: true,
    env: FrontendConfiguration.datadogGlobalContext().env,
    sampleRate: 100,
    beforeSend: (log: LogsEvent) => {
      log.view.url = obfuscateTokenFromUrl(log.view.url);
    },
  });

datadogLogs.setLoggerGlobalContext(FrontendConfiguration.datadogCustomGlobalContext(FrontendConfiguration.DD_RESERVATION_MANAGEMENT_UI));

const OptimizelyRootApp = () => {
  const AppWrapped = withOptimizely(App);

  useEffect(() => {
    setLogger(null);
  }, []);

  return (
    <OptimizelyProvider optimizely={optimizely} user={{id: nanoid()}}>
      <AppWrapped />
    </OptimizelyProvider>
  );
};

const RootApp = () => {
  return (
    <ImplicitFlow {...FrontendConfiguration.implicitFlowConfig()} followRedirect={true}>
      <Provider store={store}>
        <OptimizelyRootApp />
      </Provider>
    </ImplicitFlow>
  );
};

root.render(
  <React.StrictMode>
    <ThemeProvider theme={DefaultTheme}>
      {isLocal ? (
        <RootApp />
      ) : (
        <JwtValidator jwt={token} base64Key={adminConfig.publicKey} storageName={FrontendConfiguration.tokenStorageName}>
          <RootApp />
        </JwtValidator>
      )}
    </ThemeProvider>
  </React.StrictMode>
);
