export const FEE_CATEGORY_TAGS = {
    PET: "PET",
    TRIP_PROTECTION: "TRIPPROTECTION",
    EXTENDED: "EXTENDED",
};

export const FEE_NAMES = {
    PET: "PetFee",
    TRIP_PROTECTION: "TripProtectionFee",
    EXTENDED: "ExtendedFee",
};

//Addons button list to display in the addon menu if is allowed
export const ADDONS_BUTTON_LIST = [
    {id: 1, tag: FEE_CATEGORY_TAGS.PET, name: "Pet", allowed: true},
    {id: 2, tag: FEE_CATEGORY_TAGS.TRIP_PROTECTION, name: "Trip Protection", allowed: true},
];

//Addons toggle list to display in the addon menu if is allowed
export const ADDONS_TOGGLE_LIST = [{id: 1, tag: FEE_CATEGORY_TAGS.EXTENDED, name: "Late Checkout", allowed: true}];

//Time to redirect to reservation finance page after the addon is added or removed. This value is in milliseconds!
export const TIME_TO_REDIRECT = 5000;

export enum AddonActions {
    PREVIEW = "Preview",
    PERSIST = "Persist",
}

//TODO: Add info
export const ADDON_TYPE = {
    BUTTON: "BUTTON",
    TOGGLE: "TOGGLE",
};

/**
 * Days before the reservation where the user can apply late checkout from the last night
 */
export const DAYS_BEFORE_RESERVATION_START = 1;

/**
 * Days before the reservation where the user can apply late checkout from the checkout
 */
export const DAYS_BEFORE_CHECKOUT = DAYS_BEFORE_RESERVATION_START + 1;

/**
 * Starting hour to get late checkout.
 * Available from the day before last night at 8AM.
 */
export const OFFER_START_HOUR = 8;

export const LCO_REPORTS_SLACK_CHANNEL: string = "https://vacasa.slack.com/archives/C05BP95LBU3";
export const TP_REPORTS_SLACK_CHANNEL: string = "https://vacasa.slack.com/archives/C06N95ALSRH";

export const POLICY_KEYS = Object.freeze({
    ADJUST_FEE: "adjustFee",
    ADJUST_RENT: "adjustRent",
    ADJUST_TAX: "adjustTax",
    ADJUST_PET: "adjustPet",
    LATE_CHECKOUT: "lateCheckout",
    TRIP_PROTECTION: "tripProtection",
});

export const VALIDATE_GUEST_RESIDENCY = {
    STANDARD: "I acknowledge guest is US & Canadian Travelers eligible (excluding residents of Quebec)",
    CFAR: "I acknowledge guest is US & Canadian Travelers eligible (excluding residents of New York and Quebec)",
};

export const TRIP_PROTECTION_PROVIDERS_IDS = {
    RED_SKY: "5",
    PLAY_TRAVEL_PROTECTION_STD: "1350",
    PLAY_TRAVEL_PROTECTION_CFAR: "1351",
};

// Old Rental Guardian
export const TRIP_PROTECTION_PROVIDERS = {
    RED_SKY: "Red Sky",
    PLAY_TRAVEL_PROTECTION_STD: "Play Travel Protection STD",
    PLAY_TRAVEL_PROTECTION_CFAR: "Play Travel Protection CFAR",
};

// adjustment to tag object
export const OPTIMIZELY_MATCH = {
    PET: "adjustPet",
    TRIPPROTECTION: "tripProtection",
    ExtendedFee: "lateCheckout",
};

export const LATE_CHECKOUT_VALIDATION_MESSAGES = {
    NOT_OFFERED: "Late checkout is not offered for this unit.",
    TOO_CLOSE: "Reservation is too close to checkout date to add late checkout.",
    TOO_FAR: `Late checkout can only be applied within ${DAYS_BEFORE_CHECKOUT} days of checkout. After ${OFFER_START_HOUR} am`,
    BLOCKED: "A B2B reservation is blocking late checkout.",
};
