import React from "react";
import {useParams} from "react-router-dom";

type Props = {
  title: string;
};

export const HeaderTitle = ({title}: Props) => {
  //Get the reservation ID from the url
  const {id: reservationId} = useParams<{id: string}>();

  return (
    <h1 className="public-font-bold">
      {title} | {reservationId}
    </h1>
  );
};
