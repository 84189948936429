import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {EventService, ReservationImplicitService} from "@common/services";
import {LegacyUrlGenerator, mapQuotesErrorMessages} from "@common/utils";
import {FrontendConfiguration} from "@common/configuration";
import {
    AddonPersistPayload,
    AddonPersistResponse,
    AddonPreviewData,
    AddonPreviewPayload,
    AddonPreviewResponse,
    Addon,
    AddonNegativeDifferencesData,
    PersistPartialData,
} from "@common/typing";
import {redirectToURL, getNegativeDifferences} from "../utils";
import {TIME_TO_REDIRECT, AddonActions, ADDON_TYPE} from "../constants";
import isEmpty from "lodash/isEmpty";

// Service singletons
const reservationService = ReservationImplicitService.getInstance();

export const validateReservation = createAsyncThunk("addons/validateReservation", async (reservationId: number) => {
    const reservation = await reservationService.getReservation(reservationId, false);
    if (isEmpty(reservation?.data)) {
        return false;
    }

    return reservation?.data[0];
});

export const getAddonPreview = createAsyncThunk(
    "addons/getAddonPreview",
    async (data: AddonPreviewData, {getState}): Promise<AddonPreviewResponse> => {
        const state: any = getState();
        const legacyReservationId: number = state.reservation.reservationAttributes?.legacy_reservation_id;
        const addonSelected: Addon = state.addons.addon?.selected || state.reservation.reservationAddonsToDisplay[0];
        //Destructure data required for payload
        const {
            reservation_id: id,
            addon_fees: {fee_id, quantity, fee_category_tag, region_id, check_out_rate, replacement_reservation_id},
            adjusted_by_email: userEmail,
            addonType,
        } = data;
        const payload: AddonPreviewPayload = {
            data: {
                type: "adjustment_preview",
                attributes: {
                    addon_fees: [
                        {
                            ...(fee_id && {fee_id}),
                            quantity,
                            fee_category_tag,
                            ...(region_id && {region_id: region_id?.toString()}),
                            ...(check_out_rate && {check_out_rate: addonType === ADDON_TYPE.BUTTON ? 0 : check_out_rate}),
                            ...(replacement_reservation_id && {replacement_reservation_id}),
                        },
                    ],
                    adjusted_by_email: userEmail,
                },
            },
        };

        try {
            const response = await reservationService.getAddonPreview(id, payload);
            // Dispatch event to datadog when addon preview is successful
            EventService.dispatchAddon({legacyReservationId, payload, response}, EventService.INFO_LEVEL, AddonActions.PREVIEW);

            const originalFinances = response?.data?.attributes?.original_finances;
            const previewFinances = response?.data?.attributes?.preview_finances;
            const negativeDifferences = getNegativeDifferences(originalFinances, previewFinances, addonSelected);

            const negativeDifferencesData = {
                legacyReservationId,
                negativeDifferences,
                payload,
                response,
                typeOfAddon: fee_category_tag,
            };

            if (Object.values(negativeDifferences).some(Boolean) && quantity > state.reservation.reservationAddonsToDisplay[0]?.current_quantity) {
                EventService.dispatchAddonNegativeDifferences(negativeDifferencesData, AddonActions.PREVIEW);
            }

            return {...response, addonType};
        } catch (error) {
            const errors = error?.data?.errors?.[0];
            const errorToDisplay =
                errors?.title === "Quote Service Error"
                    ? mapQuotesErrorMessages(error)
                    : errors?.detail?.message || errors?.detail || "Unknown Error";
            //Dispatch event to datadog when addon preview fails
            EventService.dispatchAddon({legacyReservationId, payload, response: error}, EventService.ERROR_LEVEL, AddonActions.PREVIEW);
            throw new Error(
                `Error fetching the addon preview. Please try again later. If this message persists, please contact support. Details: ${errorToDisplay}`
            );
        }
    }
);

export const persistAddon = createAsyncThunk("addons/persistAddon", async (data: PersistPartialData, {getState}): Promise<AddonPersistResponse> => {
    const {reservation_id: id, adjustment_preview_id, legacy_reservation_id, addonType} = data;
    const client = FrontendConfiguration.getVacasaClient();
    const state: any = getState();
    const addonSelected: Addon = state.addons.addon?.selected || state.reservation.reservationAddonsToDisplay[0];
    const typeOfAddon: string = state.addons.addon?.selected?.fee_category_tag || state.reservation.reservationAddonsToDisplay[0]?.fee_category_tag;
    const currentQuantity =
        state.reservation.reservationAddonsToDisplay[0]?.current_quantity || state.addons.addonToggle.quantity || state.addons.addonButton.quantity;

    const payload: AddonPersistPayload = {
        data: {
            type: "update_addons",
            attributes: {
                client,
                adjustment_preview_id,
            },
        },
    };

    try {
        const response = await reservationService.postAddonPreview(id, payload);
        // Dispatch event to datadog when addon persist is successful
        EventService.dispatchAddon({legacyReservationId: legacy_reservation_id, payload, response}, EventService.INFO_LEVEL, AddonActions.PERSIST);

        const addonPreview = state.addons.addonToggle.preview || state.addons.addonButton.preview;
        const addonQuantity = state.addons.addonToggle.quantity || state.addons.addonButton.quantity;

        const originalFinances = addonPreview?.data?.attributes?.original_finances;
        const previewFinances = addonPreview?.data?.attributes?.original_finances;
        const negativeDifferences = getNegativeDifferences(originalFinances, previewFinances, addonSelected);

        const negativeDifferencesData: AddonNegativeDifferencesData = {
            legacyReservationId: legacy_reservation_id,
            negativeDifferences,
            payload,
            response,
            typeOfAddon,
        };

        if (Object.values(negativeDifferences).some(Boolean) && addonQuantity > currentQuantity) {
            EventService.dispatchAddonNegativeDifferences(negativeDifferencesData, AddonActions.PERSIST);
        }

        redirectToURL(LegacyUrlGenerator.toReservationFinance(state.reservation.reservationAttributes.legacy_reservation_id), TIME_TO_REDIRECT);
        return {...response, addonType};
    } catch (error) {
        const errors = error?.data?.errors?.[0];
        const errorToDisplay = errors?.title === "Quote Service Error" ? mapQuotesErrorMessages(error) : errors?.detail?.message || "Unknown Error";
        //Dispatch event to datadog when addon persist fails
        EventService.dispatchAddon(
            {legacyReservationId: legacy_reservation_id, payload, response: error},
            EventService.ERROR_LEVEL,
            AddonActions.PERSIST
        );
        throw new Error(
            `Error persisting the addon. Please try again later. If this message persists, please contact support. Details: ${errorToDisplay}`
        );
    }
});

export const deleteAddon = createAsyncThunk(
    "addons/deleteAddon",
    async (data: AddonPreviewData, {getState, dispatch}): Promise<AddonPersistResponse> => {
        const state: any = getState();
        const addonPreview = state.addons.addonToggle.preview || state.addons.addonButton.preview;

        //If there is an addon preview, we just return, otherwise we send the request to delete the addon
        if (addonPreview && state.reservation.reservationAddonsToDisplay?.[0]?.current_quantity < 1) return;

        const {
            reservation_id: id,
            legacy_reservation_id,
            addon_fees: {fee_id, fee_category_tag, quantity, region_id},
            adjusted_by_email,
            addonType,
        } = data;

        const dataRequest: AddonPreviewData = {
            reservation_id: id,
            addon_fees: {
                fee_id,
                quantity,
                fee_category_tag,
                region_id,
                check_out_rate: 1,
            },
            adjusted_by_email,
        };

        const addonPreviewResponse = await dispatch(getAddonPreview(dataRequest)).unwrap();
        const addonPersistResponse = await dispatch(
            persistAddon({reservation_id: data.reservation_id, adjustment_preview_id: addonPreviewResponse?.data?.id})
        ).unwrap();
        redirectToURL(LegacyUrlGenerator.toReservationFinance(legacy_reservation_id), TIME_TO_REDIRECT);
        return {...addonPersistResponse, addonType};
    }
);
export interface AddonsState {
    modal: {
        add: boolean;
        edit: boolean | string;
        transfer: boolean;
        delete: boolean;
        deleteRecommended: boolean;
        error: boolean;
        errorMessage: string;
    };
    action: {
        loading: boolean;
        saving: boolean;
        deleting: boolean;
        deletingRecommended: boolean;
    };
    alert: {
        success: boolean;
        fail: boolean;
    };
    addonToggleList: Addon[];
    addonButtonList: Addon[];
    addonToggle: {
        saved: boolean;
        checked: boolean;
        preview: AddonPreviewResponse;
        quantity: number;
        selected: Addon;
        loading: boolean;
        deletePreview: boolean;
    };
    addonButton: {
        saved: boolean;
        checked: boolean;
        preview: AddonPreviewResponse;
        quantity: number;
        selected: Addon;
        loading: boolean;
    };
    tripProtectionSelected: {
        provider: string;
        isTripProtectionWithTransfer: boolean;
    };
}

export const initialState: AddonsState = {
    modal: {
        add: false,
        edit: false,
        transfer: false,
        delete: false,
        deleteRecommended: false,
        error: false,
        errorMessage: "An error ocurred while generating the addon preview or persisting the changes",
    },
    action: {
        loading: false,
        saving: false,
        deleting: false,
        deletingRecommended: false,
    },
    alert: {
        success: false,
        fail: false,
    },
    addonToggleList: null,
    addonButtonList: null,
    addonToggle: {
        saved: false,
        checked: false,
        preview: null,
        quantity: null,
        selected: null,
        loading: false,
        deletePreview: false,
    },
    addonButton: {
        saved: false,
        checked: true,
        preview: null,
        quantity: null,
        selected: null,
        loading: false,
    },
    tripProtectionSelected: {
        provider: null,
        isTripProtectionWithTransfer: false,
    },
};

const addonsSlice = createSlice({
    name: "addons",
    initialState,
    reducers: {
        setAddonsToggle: (state, action) => {
            state.addonToggleList = action.payload;
        },
        setAddonsButton: (state, action) => {
            state.addonButtonList = action.payload;
        },
        updateAddAddonModal: (state, {payload}) => {
            state.modal.add = payload;
        },
        displayAddNewAddonModal: (state, addon_tag) => {
            state.modal.edit = addon_tag.payload;
        },
        hideAddNewAddonModal: (state) => {
            state.modal.edit = false;
        },
        displayTransferAddonModal: (state) => {
            state.modal.transfer = true;
        },
        hideTransferAddonModal: (state) => {
            state.modal.transfer = false;
        },
        displayDeleteAddonModal: (state) => {
            state.modal.delete = true;
        },
        displayDeleteAddonRecommendedModal: (state) => {
            state.modal.deleteRecommended = true;
        },
        hideDeleteAddonModal: (state) => {
            state.modal.delete = false;
        },
        hideDeleteAddonRecommendedModal: (state) => {
            state.modal.deleteRecommended = false;
        },
        displaySuccessAddonAlert: (state) => {
            state.alert.success = true;
        },
        hideSuccessAddonAlert: (state) => {
            state.alert.success = false;
        },
        displayFailAddonAlert: (state) => {
            state.alert.fail = true;
        },
        cleanAddonTogglePreview: (state) => {
            state.addonToggle.preview = null;
            state.addonToggle.checked = false;
            state.addonToggle.quantity = null;
            state.addonToggle.selected = null;
        },
        cleanAddonButtonPreview: (state) => {
            state.modal.delete = false;
            state.modal.deleteRecommended = false;
            state.addonButton.preview = null;
            state.addonButton.quantity = null;
        },
        setAddonChecked: (state, {payload}) => {
            if (payload.addonType === ADDON_TYPE.BUTTON) {
                state.addonButton.checked = payload.checked;
            } else {
                state.addonToggle.checked = payload.checked;
            }
        },
        setAddonQuantity: (state, {payload}) => {
            if (payload.addonType === ADDON_TYPE.BUTTON) {
                state.addonButton.quantity = payload.quantity;
            } else {
                state.addonToggle.quantity = payload.quantity;
            }
        },
        hideAddonErrorModal: (state) => {
            state.modal.error = false;
        },
        setAddonButtonSelected: (state, {payload}) => {
            state.addonButton.selected = payload;
        },
        setAddonToggleSelected: (state, {payload}) => {
            state.addonToggle.selected = payload;
        },
        setToggleLoading: (state, {payload}) => {
            state.addonToggle.loading = payload;
        },
        setAddonDeletePreview: (state, {payload}) => {
            state.addonToggle.deletePreview = payload;
        },
        setButtonLoading: (state, {payload}) => {
            state.addonButton.loading = payload;
        },
        setTripProtectionSelected: (state, {payload}) => {
            state.tripProtectionSelected = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAddonPreview.pending, (state) => {
                state.action.loading = true;
            })
            .addCase(getAddonPreview.fulfilled, (state, {payload}: any) => {
                state.action.loading = false;
                state.modal.edit = false;
                state.modal.delete = false;
                state.modal.deleteRecommended = false;
                state.modal.transfer = false;
                if (payload.addonType === ADDON_TYPE.BUTTON) {
                    state.addonButton.preview = payload;
                } else {
                    state.addonToggle.preview = payload;
                }
            })
            .addCase(getAddonPreview.rejected, (state, {error}) => {
                state.modal.errorMessage = error?.message;
                state.action.loading = false;
                state.modal.edit = false;
                state.modal.transfer = false;
                state.modal.error = true;
                state.modal.deleteRecommended = false;
                state.modal.delete = false;
                if (!state.addonToggle.checked) {
                    state.addonToggle.checked = true;
                }
            })
            .addCase(persistAddon.pending, (state) => {
                state.action.saving = true;
            })
            .addCase(persistAddon.fulfilled, (state, {payload}) => {
                state.action.saving = false;
                state.alert.success = true;
                if (payload?.addonType === ADDON_TYPE.BUTTON) {
                    state.addonButton.saved = true;
                } else {
                    state.addonToggle.saved = true;
                }
            })
            .addCase(persistAddon.rejected, (state, {error}) => {
                state.modal.errorMessage = error?.message;
                state.action.saving = false;
                state.alert.fail = true;
                state.modal.error = true;
            })
            .addCase(deleteAddon.pending, (state) => {
                state.action.deleting = true;
                state.action.deletingRecommended = true;
            })
            .addCase(deleteAddon.fulfilled, (state, {payload}) => {
                state.action.deleting = false;
                state.action.deletingRecommended = true;
                state.modal.delete = false;
                state.modal.deleteRecommended = false;
                state.addonToggle.preview = null;
                state.addonButton.preview = null;
                state.addonToggle.selected = null;
                state.addonButton.selected = null;
                if (payload?.addonType === ADDON_TYPE.BUTTON) {
                    state.addonButton.saved = true;
                } else {
                    state.addonToggle.saved = true;
                }
            })
            .addCase(deleteAddon.rejected, (state, {error}) => {
                state.action.deleting = false;
                state.action.deletingRecommended = true;
                state.modal.errorMessage = error?.message;
                state.modal.delete = false;
                state.modal.deleteRecommended = false;
                state.modal.error = true;
            });
    },
});

export const {
    displayAddNewAddonModal,
    displayTransferAddonModal,
    hideAddNewAddonModal,
    hideTransferAddonModal,
    displayDeleteAddonModal,
    displayDeleteAddonRecommendedModal,
    hideDeleteAddonModal,
    hideDeleteAddonRecommendedModal,
    displaySuccessAddonAlert,
    hideSuccessAddonAlert,
    setAddonChecked,
    displayFailAddonAlert,
    cleanAddonButtonPreview,
    cleanAddonTogglePreview,
    setAddonQuantity,
    hideAddonErrorModal,
    setAddonButtonSelected,
    setAddonToggleSelected,
    setAddonsToggle,
    setAddonsButton,
    setToggleLoading,
    setButtonLoading,
    setAddonDeletePreview,
    updateAddAddonModal,
    setTripProtectionSelected,
} = addonsSlice.actions;

export default addonsSlice.reducer;
