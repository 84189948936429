import React from "react";
import ContentLoader from "react-content-loader";

export const AddonGenericLoader = (): JSX.Element => {
  return (
    <ContentLoader viewBox="0 0 1300 60" height={60} width={1300}>
      <rect x="34" y="13" rx="6" ry="6" width={200} height="30" />
      <rect x="1040" y="13" rx="6" ry="6" width={250} height="30" />
    </ContentLoader>
  );
};

export const AddonToggleLoading = ({isLoadingPreview}): JSX.Element => {
  return isLoadingPreview ? (
    <ContentLoader width={1400} height={400} viewBox="0 0 1400 400" backgroundColor="#eaeced" foregroundColor="#ffffff">
      <rect x="400" y="40" rx="3" ry="3" width="280" height="25" />
      <rect x="1200" y="40" rx="3" ry="3" width="120" height="18" />
      <rect x="50" y="90" rx="3" ry="3" width="141" height="15" />
      <rect x="100" y="90" rx="3" ry="3" width="100" height="0" />
      <rect x="50" y="130" rx="3" ry="3" width="141" height="15" />
      <rect x="400" y="130" rx="3" ry="3" width="100" height="15" />
      <rect x="50" y="170" rx="3" ry="3" width="141" height="15" />
      <rect x="400" y="170" rx="3" ry="3" width="100" height="15" />
      <rect x="50" y="210" rx="3" ry="3" width="141" height="15" />
      <rect x="400" y="210" rx="3" ry="3" width="100" height="15" />
      <rect x="50" y="250" rx="3" ry="3" width="141" height="15" />
      <rect x="400" y="250" rx="3" ry="3" width="100" height="15" />
      <rect x="50" y="290" rx="3" ry="3" width="141" height="15" />
      <rect x="400" y="290" rx="3" ry="3" width="150" height="15" />
      <rect x="1150" y="290" rx="3" ry="3" width="200" height="25" />
    </ContentLoader>
  ) : (
    <AddonGenericLoader />
  );
};
